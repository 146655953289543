import useAppStore from '../store/use-app-store';

const useAddPlayersContract = () => {
  const [
    walletConnected,
    signer,
    loading,
    players,
    usedPlayerIds
  ] = useAppStore((state) => [
    state.walletState.walletConnected,
    state.walletState.signer,
    state.loadingPlayers,
    state.playersState.players,
    state.matchesState.usedPlayerIds
  ]);

  return {
    walletConnected,
    signer,
    loading,
    players,
    usedPlayerIds
  };
}

export default useAddPlayersContract;
