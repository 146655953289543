import React from 'react';
import { Router } from 'wouter';

import Header from './components/header';
import Content from './components/content';
import Footer from './components/footer';
import ToastList from './components/toast-list';

const Layout = () => {
  return (
    <Router>
      <Header />
      <Content />
      <Footer />
      <ToastList />
    </Router>
  );
}

export default Layout;
