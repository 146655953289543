import React from 'react';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TrainingCenterCard from '../../shared/components/training-center-card';
import useTrainingsContract from '../../hooks/use-trainings-contract';
import Loading from '../../shared/components/loading';
import CustomCountdown from '../../shared/components/custom-countdown';

const TrainingsPage = () => {
  const {
    trainingCenters,
    loading,
    started
  } = useTrainingsContract();

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <header className="hero is-dark"  style={{ backgroundImage: 'url(/images/banner.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <div className="hero-body has-text-centered">
          <div className="container">
            <h1 className="title has-text-light">Training Centers</h1>
          </div>
        </div>
      </header>
      {!started ? (
        <header className="hero is-dark is-small" style={{ backgroundImage: 'url(/images/banner.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
          <div className="hero-body has-text-centered">
            <div className="container">
              <CustomCountdown title="Countdown to Training" value={process.env.REACT_APP_TRAINING_START_AT} />
            </div>
          </div>
        </header>
      ) : null}
      <main role="main" className="section has-background-white is-clipped">
        <div className="container">
          <div className="columns is-desktop is-align-items-center mb-6">
            {trainingCenters.map(tr => (
              <div key={`trainig-center-${tr.id}`} className="column">
                <TrainingCenterCard
                  id={tr.id}
                  to={`/trainings/${tr.id}`}
                  fee={tr.fee}
                  qtyAllow={tr.qtyAllow}
                  started={started}
                />
              </div>
            ))}
          </div>
          <div className="columns is-variable is-8 is-desktop">
            <div className="column">
              <div className="is-relative mb-6">
                <div className="box has-background-success" style={{ position: 'absolute', top: '3rem', left: '-3rem', width: '100%', height: '100%' }} />
                <div className="is-relative">
                  <svg
                    className="has-text-muted is-hidden-mobile"
                    style={{ position: 'absolute', top: '-3rem', right: '-3rem' }}
                    width={404}
                    height={392}
                    fill="none"
                    viewBox="0 0 404 392"
                  >
                    <defs>
                      <pattern
                        id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                        x={0}
                        y={0}
                        width={20}
                        height={20}
                        patternUnits="userSpaceOnUse"
                      >
                        <rect x={0} y={0} width={4} height={4} className="has-text-muted" fill="currentColor" />
                      </pattern>
                    </defs>
                    <rect width={404} height={392} fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
                  </svg>
                  <div className="card">
                    <figure className="image is-16by9">
                      <img src="/images/estadios-qatar.png" alt="Stadiums" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="content is-large">
                <article className="message is-success">
                  <div className="message-body">
                    <span className="is-italic">Train and enhance</span>
                  </div>
                </article>
                <ul style={{ listStyleImage: 'url(/images/logo-small.png)' }}>
                  <li>Train your team, like the pros.</li>
                  <li>Enhance your NFTs here.</li>
                  <li>Get better training results with higher levels.</li>
                  <li>Earn collectible NFTs too!</li>
                </ul>
              </div>
              <p className="mb-5">
                <a href="https://docs.degoverse.bet/fundamentals/training-centers" target="_blank" rel="noreferrer" className="button is-text">
                  <span className="icon-text">
                    <span>Read more</span>
                    <span className="icon">
                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    </span>
                  </span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default TrainingsPage;
