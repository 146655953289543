import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'wouter';

import useLocation from '../../../hooks/use-location';

// Home Section
import ProfilePage from '../../../pages/profile';
import SalePage from '../../../pages/sale';
import PurchasePage from '../../../pages/purchase';
import SportSalePage from '../../../pages/sport-sale';
import SportPurchasePage from '../../../pages/sport-purchase';
import GameCardSalePage from '../../../pages/game-card-sale';
import GameCardPurchasePage from '../../../pages/game-card-purchase';
import PlayerPage from '../../../pages/player';
import SportPage from '../../../pages/sport';
import GameCardPage from '../../../pages/game-card';
import ReferralsPage from '../../../pages/referrals';
import TrainingsPage from '../../../pages/trainings';
import TrainingPage from '../../../pages/training';
import WorldCupGamePage from '../../../pages/world-cup-game';
import WorldCupStandingsPage from '../../../pages/world-cup-standings';
import WorldCupBracketPage from '../../../pages/world-cup-bracket';

const Content = () => {
  const [, , search] = useLocation();

  useEffect(() => {
    const q = new URLSearchParams(search);
    const value = q.get('referrer');
    if (value) {
      const expireAt = Date.now() + (86400 * 7 * 1000);
      localStorage.setItem('referrer', JSON.stringify({ value, expireAt }));
    }
  }, [search]);

  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="/world-cup-game" />
      </Route>
      <Route path="/profile">
        <ProfilePage />
      </Route>
      <Route path="/sale">
        <SalePage />
      </Route>
      <Route path="/sport-sale">
        <SportSalePage />
      </Route>
      <Route path="/game-card-sale">
        <GameCardSalePage />
      </Route>
      <Route path="/players/:id">
        {(params) => <PlayerPage id={params.id} />}
      </Route>
      <Route path="/sports/:id">
        {(params) => <SportPage id={params.id} />}
      </Route>
      <Route path="/game-cards/:id">
        {(params) => <GameCardPage id={params.id} />}
      </Route>
      <Route path="/purchases/:id">
        {(params) => <PurchasePage id={params.id} />}
      </Route>
      <Route path="/sport-purchases/:id">
        {(params) => <SportPurchasePage id={params.id} />}
      </Route>
      <Route path="/game-card-purchases/:id">
        {(params) => <GameCardPurchasePage id={params.id} />}
      </Route>
      <Route path="/referrals">
        <ReferralsPage />
      </Route>
      <Route exact path="/trainings">
        <TrainingsPage />
      </Route>
      <Route path="/trainings/:tcId">
        {(params) => <TrainingPage tcId={params.tcId} />}
      </Route>
      <Route exact path="/world-cup-game">
        <WorldCupGamePage />
      </Route>
      <Route exact path="/world-cup-standings">
        <WorldCupStandingsPage />
      </Route>
      <Route exact path="/world-cup-bracket">
        <WorldCupBracketPage />
      </Route>

      <Route path="*">
        <Redirect push to="/" />
      </Route>
    </Switch>
  );
}

export default Content;
