import React from 'react';
import MatchInfo from './components/match-info';
import Tvl from './components/tvl';
import UserBet from './components/user-bet';
import UserClaim from './components/user-claim';

const MatchRow = ({ match, setBetOnMatch, setAddPlayersOnMatch }) => {
  return (
    <div className="box">
      <div className="columns is-multiline">
        <MatchInfo match={match} />
        <Tvl match={match} />
        <UserBet match={match} setBetOnMatch={setBetOnMatch} setAddPlayersOnMatch={setAddPlayersOnMatch} />
        <UserClaim match={match} />
      </div>
    </div>
  );
}

export default MatchRow;
