const asyncFetchGameCardsData = async (prevGameCards) => {
  let gameCards = [...prevGameCards];

  for (let i = 0; i < gameCards.length; i++) {
    if (gameCards[i].quantity > 0 && gameCards[i].name === undefined) {
      let response = await fetch(`${process.env.REACT_APP_META_API_URL}/cards/${gameCards[i].id}`);
      let gameCardMetaData = await response.json();
  
      gameCards[i] = Object.assign({}, {...gameCards[i]}, {...gameCardMetaData});
    }
  }

  return {gameCards};
}

export default asyncFetchGameCardsData;
