import { getAddress, loadContract, multiCall } from '../services/utils';
import sportNftAbi from '../config/abis/sport-nft.json';
import asyncFetchSportsData from './async-fetch-sports-data';

const asyncFetchSports = async (prevSports, connectedWallet) => {
  let sports = [];

  if (!connectedWallet) {
    return {sports};
  }

  const sportNftContract = loadContract('sportNft', sportNftAbi);

  const sportNftAddress = getAddress('sportNft');
  const balance = await sportNftContract.balanceOf(connectedWallet);

  const idsCalls = [];
  for (let i = 0; balance.gt(i); i++) {
    idsCalls.push({
      name: 'tokenOfOwnerByIndex',
      address: sportNftAddress,
      params: [connectedWallet, i]
    });
  }
  const idsRs = await multiCall(sportNftAbi, idsCalls);
  for (let i = 0; i < idsRs.length; i++) {
    let id = idsRs[i][0].toNumber();
    let sport = prevSports.find((sp) => sp.id === id);
    sports.push(Object.assign({...sport}, {id}));
  }

  return {
    ...await asyncFetchSportsData(sports)
  }
}

export default asyncFetchSports;
