import { Interface } from '@ethersproject/abi';
import multicallAbi from '../config/abis/multicall.json';
import { ethers } from 'ethers';

import { contractsMainNet } from '../config/contracts';
import BigNumber from 'bignumber.js';

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
}

export const getAddress = (address) => {
  if (address.startsWith('0x')) {
    return address;
  }

  return contractsMainNet.get(address);
}

export const loadContract = (address, abi, signer = null) => {
  const provider = signer || new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_NODE_1);
  return new ethers.Contract(getAddress(address), abi, provider);
}

export const multiCall = async (abi, calls) => {
  const itf = new Interface(abi);
  const multicallContract = loadContract('multiCall', multicallAbi);
  const calldata = calls.map((call) => [
    call.address.toLowerCase(),
    itf.encodeFunctionData(call.name, call.params || [])
  ]);
  const { returnData } = await multicallContract.aggregate(calldata);

  return returnData.map((call, i) => itf.decodeFunctionResult(calls[i].name, call));
}

export const numberWithDecimals = (number, decimals) => {
  return BigNumber(number).dividedBy(new BigNumber(10).pow(decimals));
}
