import React, { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { request, gql } from 'graphql-request'
import confetti from 'canvas-confetti';

import PlayerCard from '../../shared/components/player-card';
import Loading from '../../shared/components/loading';
import useLocation from '../../hooks/use-location';

const PurchasePage = ({ id }) => {
  const [, , search] = useLocation();
  const [purchase, setPurchase] = useState({});
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [attemps, setAttemps] = useState(0);

  useEffect(() => {
    const fetchPurchase = async () => {
      const queryPurchase = gql`
        {
          purchase(id: "${id}") {
            id
            nftIds
            saleQty
            amountUsd
            purchasedAtTimestamp
            discount {
              per
            }
            salePack {
              id
              qty
              price
            }
          }
        }
      `;

      try {
        const rsPurchase = await request(process.env.REACT_APP_GRAPHQL_URL, queryPurchase);

        if (rsPurchase.purchase) {
          setPurchase(rsPurchase.purchase);

          const queryPlayer = gql`
            {
              players(first: ${rsPurchase.purchase.nftIds.length} orderBy: id where: {id_in: [${rsPurchase.purchase.nftIds.join(',')}] }) {
                id
                description
                name
                externalUrl
                image
                defense
                attack
                physical
                morale
                experience
                country
                position
                foot
                birth
                background
                ball
                ethnicity
                legTattoos
                armTattoos
                angkleBoots
                jerseyPlayer
                countryLogo
                hair
                updatedAtTimestamp
                owner {
                  id
                }
              }
            }
          `;

          const rsPlayer = await request(process.env.REACT_APP_GRAPHQL_URL, queryPlayer);
          if (rsPlayer.players?.length > 0) {
            setPlayers(rsPlayer.players);
          }

          setLoading(false);
        } else {
          setAttemps(prevState => prevState + 1);
        }
      } catch(error) {
        console.log(error);
      };
    }

    const interval = setInterval(fetchPurchase, 2000);

    fetchPurchase();

    return () => clearInterval(interval);
  }, [id, setPurchase, setPlayers, setLoading, setAttemps]);

  useEffect(() => {
    if (attemps > 0 && attemps % 10 === 0) {
      window.location.reload();
    }
  }, [attemps]);

  useEffect(() => {
    if (!loading) {
      const queryParams = new URLSearchParams(search);
      if (queryParams.get('from') === 'sale') {
        confetti({
          resize: true,
          particleCount: 200,
          startVelocity: 30,
          gravity: 0.5,
          spread: 350,
          origin: {
            x: 0.5,
            y: 0.3,
          },
        });
      }
    }

  }, [loading, search]);

  const renderResults = () => {
    if (loading) {
      return <Loading />;
    }

    return (
      <>
        <header className="hero is-light is-small">
          <div className="hero-body has-text-centered">
            <div className="container">
              <h1 className="title has-text-primary">Purchase Summary</h1>
            </div>
          </div>
        </header>
        <main role="main" className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-half">
                <div className="box">
                  <table className="table is-fullwidth">
                    <tbody>
                      <tr>
                        <td>TX</td>
                        <td className="has-text-right">
                          <a href={`${process.env.REACT_APP_EXPLORER}/tx/${purchase.id}`} target="_blank" rel="noreferrer">
                            <span className="icon-text">
                              <span>
                                {`${purchase.id.slice(0, 4)}...${purchase.id.slice(purchase.id.length - 4)}`}
                              </span>
                              <span className="icon">
                                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                              </span>
                            </span>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Lootbox #{Number(purchase.salePack.id) + 1}</td>
                        <td className="has-text-right">x{purchase.salePack.qty} NFTs</td>
                      </tr>
                      <tr>
                        <td>Quantity</td>
                        <td className="has-text-right">{purchase.saleQty}</td>
                      </tr>
                      <tr>
                        <td>NFTs received</td>
                        <td className="has-text-right">{new BigNumber(purchase.saleQty).times(purchase.salePack.qty).toFormat(0)}</td>
                      </tr>
                      <tr>
                        <td>Lootbox Price <small className="is-italic">(USDC)</small></td>
                        <td className="has-text-right">${new BigNumber(purchase.salePack.price).dividedBy(new BigNumber(10).pow(6)).toFormat(2)}</td>
                      </tr>
                      {purchase.discount ? (
                        <tr>
                          <td>Discount</td>
                          <td className="has-text-right has-text-success">{new BigNumber(purchase.discount.per).dividedBy(100).toFormat()}%OFF</td>
                        </tr>
                      ) : null}
                      <tr>
                        <td>Total <small className="is-italic">(USDC)</small></td>
                        <td className="has-text-right has-text-primary has-text-weight-bold">${new BigNumber(purchase.amountUsd).dividedBy(new BigNumber(10).pow(6)).toFormat(2)}</td>
                      </tr>
                      <tr>
                        <td>Purchased at</td>
                        <td className="has-text-right">{new Date(purchase.purchasedAtTimestamp * 1000).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' })}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="divider is-size-4">Players</div>
            <div className="columns is-centered is-multiline">
              {players.map(player => (
                <div key={`players-${player.id}`} className="column is-one-third">
                  <h3 className="subtitle has-text-centered">{player.name}</h3>
                  <PlayerCard player={player} navigate />
                </div>
              ))}
            </div>
          </div>
        </main>
      </>
    );
  }

  return renderResults();
}

export default PurchasePage;
