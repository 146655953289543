import React, { useContext, useEffect, useState } from 'react';
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faCog, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { ToastContext } from '../../../context/toast';

import erc20Abi from '../../../config/abis/erc20.json';
import sportSaleAbi from '../../../config/abis/sport-sale.json';
import { loadContract, getAddress, classNames } from '../../../services/utils';
import useSportSaleModalContract from '../../../hooks/user-sport-sale-modal-contract';
// import useLocation from '../../../hooks/use-location';

const SportSaleModal = ({ isModalActive, handleCloseModal, currentDiscount, selectedPack }) => {
  const { addToast } = useContext(ToastContext);
  const [quantity, setQuantity] = useState(1);
  const [isPending, setIsPending] = useState(false);
  const { saleState, signer, setReload } = useSportSaleModalContract();
  // const [, setLocation, ] = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      setReload(prevState => prevState + 1);
    }, 2000);

    return () => clearInterval(interval);
  }, [setReload]);

  const handleQuantityChange = (evt) => {
    const { currentTarget } = evt;

    setQuantity(currentTarget.value);
  }

  const renderTotal = (applyDiscount = false) => {
    const total = new BigNumber(selectedPack.price).times(quantity);

    if (applyDiscount && currentDiscount) {
      return total.times(new BigNumber(10000).minus(currentDiscount.per)).dividedBy(10000);
    }

    return total;
  }

  const renderBuyBtnText = () => {
    if (new BigNumber(saleState.balanceOf).lt(renderTotal(true))) {
      return 'Insufficent funds';
    }

    if (new BigNumber(saleState.nftRemaining).lt(new BigNumber(selectedPack.qty).times(quantity))) {
      return 'No NFTs remaining';
    }

    return 'Buy!';
  }

  const disableBuyBtn = () => {
    return isPending || renderBuyBtnText() !== 'Buy!';
  }

  const handleApprove = async () => {
    setIsPending(true);
    let tx;
    try {
      const usdcContract = loadContract('usdc', erc20Abi, signer);
      tx = await usdcContract.approve(getAddress('sportSale'), ethers.constants.MaxUint256);
      await tx.wait();
      addToast('Contract approved successfully!', 'is-success');
    } catch (e) {
      tx = { error: e.data?.message || e.message };
    }

    if(tx.error !== undefined) {
      console.log('error', tx.error);
      addToast('Approve Failed.', 'is-danger');
    }

    setIsPending(false);
  }

  const referrer = () => {
    try {
      const referrer = JSON.parse(localStorage.getItem('referrer') || '{}');
      if (referrer.value) {
        if (referrer.expireAt > Date.now()) {
          return referrer.value;
        } else {
          localStorage.removeItem('referrer');
        }
      }
      return 'noreferrer';
    } catch (e) {
      return 'noreferrer';
    }
  }

  const handleBuy = async () => {
    setIsPending(true);
    let tx;
    try {
      const saleContract = loadContract('sportSale', sportSaleAbi, signer);
      tx = await saleContract.buyNft(selectedPack.id, quantity, referrer());
      await tx.wait();
      addToast('Congratulations, you bought Smart NFTs, have fun!!', 'is-success');
    } catch (e) {
      tx = { error: e.data?.message || e.message };
    }

    if(tx.error !== undefined) {
      console.log('error', tx.error);
      addToast('Sale failed.', 'is-danger');
      setIsPending(false);
    } else {
      handleCloseModal();
      addToast('Sale Success.', 'is-success');
      // setLocation(`/sport-purchases/${tx.hash}?from=sale`);
    }
  }

  const renderQuantityOptions = () => {
    const values = [];
    for (let i = 1; new BigNumber(selectedPack.maxSellQty).gte(i); i++) {
      values.push(i);
    }

    return values.map(val => (<option key={`quantity-option-${val}`} value={val}>{val}</option>))
  }

  const renderRemainingTag = () => {
    if (currentDiscount) {
      return (
        <div className="tags has-addons is-justify-content-center">
          <span className="tag is-success">Early VIP access {currentDiscount.id + 1}</span>
          <span className="tag is-primary">{(saleState.loading || isPending) ? (<span className="icon"><FontAwesomeIcon icon={faCog} spin /></span>) : new BigNumber(saleState.nftRemaining).minus(currentDiscount.qty).toFormat(0)} players remaining</span>
        </div>
      );
    }

    return (
      <div className="tags has-addons is-justify-content-center">
        <span className="tag">Public access</span>
        <span className="tag is-primary">{saleState.loading ? (<span className="icon"><FontAwesomeIcon icon={faCog} spin /></span>) : new BigNumber(saleState.nftRemaining).toFormat(0)} players remaining</span>
      </div>
    );

  }

  const renderAllowOrBuyBtn = () => {
    if (saleState.loading) {
      return (<FontAwesomeIcon icon={faSpinner} spin />);
    }

    if (new BigNumber(saleState.allowance).gt(0)) {
      return (
        <button
          onClick={handleBuy}
          disabled={disableBuyBtn()}
          className={classNames('button', 'is-primary', 'is-fullwidth', isPending && 'is-loading')}
        >
          {renderBuyBtnText()}
        </button>
      );
    }

    return (
      <button
        onClick={handleApprove}
        disabled={isPending}
        className={classNames('button', 'is-success', 'is-fullwidth', isPending && 'is-loading')}
      >
        Approve
      </button>
    );
  }

  return (
    <div className={classNames('modal', isModalActive && 'is-active')}>
      <div className="modal-background" onClick={handleCloseModal} />
      <div className="modal-content">
        <div className="box mx-3">
          <div className="columns is-vcentered">
            <div className="column">
              <h3 className="title has-text-centered">
                Lootbox <span className="has-text-dark is-size-4">x{selectedPack.qty}</span>
              </h3>
            </div>
            <div className="column is-narrow">
              {renderRemainingTag()}
            </div>
          </div>
          <hr />

          <div className="columns is-mobile is-align-items-center px-3">
            <div className="column" style={{ lineHeight: '1' }}>
              <label className="label mb-0">Quantity</label>
              <small className="is-italic has-text-secondary">Lootbox x{new BigNumber(selectedPack.qty).toNumber()} {new BigNumber(selectedPack.qty).gt(1) ? 'NFTs' : 'NFT'}</small>
            </div>
            <div className="column is-narrow">
              <div className="field has-addons has-addons-right">
                <p className="control">
                  <span className="select">
                    <select value={quantity} onChange={handleQuantityChange}>
                      {renderQuantityOptions()}
                    </select>
                  </span>
                </p>
                <p className="control">
                  <button type="button" className="button is-static">
                    x {new BigNumber(selectedPack.qty).toNumber()}
                  </button>
                </p>
              </div>
            </div>
          </div>
          <h5 className="subtitle has-text-success px-3 mb-0">Summary</h5>
          <table className="table is-fullwidth">
            <tbody>
              <tr>
                <td>
                  <label className="label">NFTs to receive</label>
                </td>
                <td className="has-text-right has-text-primary">
                  {new BigNumber(selectedPack.qty).times(quantity).toNumber()}
                </td>
              </tr>
              <tr>
                <td>
                  <label className="label">Lootbox Price (USDC)</label>
                </td>
                <td className="has-text-right">
                  ${new BigNumber(selectedPack.price).toFormat(2)}
                </td>
              </tr>
              <tr>
                <td>
                  <label className="label">
                    Total
                    {currentDiscount ? (
                      <small className="ml-1 is-italic is-size-6 has-text-success">({new BigNumber(currentDiscount.per).dividedBy(100).toFormat(2)}% off)</small>
                    ) : null}
                  </label>
                </td>
                <td className="has-text-right">
                  {currentDiscount ? (
                    <div className="is-flex is-justify-content-end is-align-items-end">
                      <h5 className="mb-0 is-size-6 is-line-through">{renderTotal().toFormat(2)}</h5>
                      <h5 className="has-text-primary mb-0 ml-1 is-size-5">${renderTotal(true).toFormat(2)}</h5>
                    </div>
                  ) : (
                    <h5 className="has-text-primary mb-0 is-size-5">${renderTotal().toFormat(2)}</h5>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <p className="mb-5">
            {renderAllowOrBuyBtn()}
          </p>
          <article className="message is-info">
            <div className="message-body">
              <p>Your USDC Balance is <span className="has-text-primary">${new BigNumber(saleState.balanceOf).toFormat(2)}</span></p>
              <p>
              <a href="https://quickswap.exchange/#/swap?outputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174" target="_blank" rel="noreferrer">
                <span className="icon-text">
                  <span>Get USDC</span>
                  <span className="icon">
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                  </span>
                </span>
              </a>
              </p>
            </div>
          </article>
        </div>
      </div>
      <button className="modal-close is-large" aria-label="close" onClick={handleCloseModal} />
    </div>
  );
}

export default SportSaleModal;
