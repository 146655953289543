import React from 'react';
import { Link } from 'wouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import GameCardNftCard from '../../../../shared/components/game-card-nft-card';
import useAppStore from '../../../../store/use-app-store';

const ProfileGameCards = () => {
  const [
    loadingGameCards,
    gameCards
  ] = useAppStore((state) => [
    state.loadingGameCards,
    state.gameCardsState.gameCards
  ]);

  return (
    <div className="columns is-centered is-multiline">
      {loadingGameCards ? (
        <div className="column is-narrow">
          <span className="icon is-large">
            <FontAwesomeIcon icon={faCog} spin size="4x" />
          </span>
        </div>
      ) : (
        gameCards.length === 0 ? (
          <div className="column is-half">
            <article className="message is-warning">
              <div className="message-body">
                Your bucket is empty. <Link to="/sport-sale">Buy here</Link> and became a superstar!
              </div>
            </article>
          </div>
        ) : (
          gameCards.map(gameCard => (
            <div key={`gameCards-${gameCard.id}`} className="column is-one-quarter">
              <GameCardNftCard gameCard={gameCard} navigate />
            </div>
          ))
        )
      )}
    </div>
  );
}

export default ProfileGameCards;
