import { getAddress, multiCall } from '../services/utils';
import nftPlayerAbi from '../config/abis/nft-player.json';

const asyncFetchPlayersData = async (prevPlayers) => {
  let players = [];

  const nftPlayerAddress = getAddress('nftPlayer');

  const skillCalls = [];
  for (let i = 0; i < prevPlayers.length; i++) {
    skillCalls.push({
      name: 'playerSkill',
      address: nftPlayerAddress,
      params: [prevPlayers[i].id]
    });
  }
  const skillRs = await multiCall(nftPlayerAbi, skillCalls);

  for (let i = 0; i < prevPlayers.length; i++) {
    players.push(Object.assign({...prevPlayers[i]}, {
      defense: skillRs[i][0].toNumber(),
      attack: skillRs[i][1].toNumber(),
      physical: skillRs[i][2].toNumber(),
      morale: skillRs[i][3].toNumber(),
      experience: skillRs[i][4].toNumber()
    }));
  }

  const aboutCalls = [];
  const aboutIds = [];
  for (let i = 0; i < players.length; i++) {
    if (players[i].position === undefined) {
      aboutCalls.push({
        name: 'playerAbout',
        address: nftPlayerAddress,
        params: [players[i].id]
      });
      aboutIds.push(i);
    }
  }
  if (aboutCalls.length > 0) {
    const aboutRs = await multiCall(nftPlayerAbi, aboutCalls);

    for (let i = 0; i < aboutRs.length; i++) {
      players[aboutIds[i]] = Object.assign({...players[aboutIds[i]]}, {
        foot: aboutRs[i][0].toNumber(),
        birth: aboutRs[i][1].toNumber(),
        position: aboutRs[i][2].toNumber(),
        country: aboutRs[i][3].toNumber()
      });
    }
  }

  return {players};
}

export default asyncFetchPlayersData;
