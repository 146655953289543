import { ethers } from 'ethers';
// import WalletConnectProvider from '@walletconnect/web3-provider';

const asyncConnectWallet = async (selectedProvider) => {
  let rs = {
    isInstalledWallet: false,
    walletConnected: undefined,
    signer: undefined
  };

  if (!selectedProvider) {
    return rs;
  }

  let web3ProviderSource;
  let web3Provider;

  if (selectedProvider === 'METAMASK') {
    web3ProviderSource = window.ethereum;
//       } else if (selectedProvider === 'WALLET_CONNECT') {
//         web3ProviderSource = new WalletConnectProvider({
//           chainId: Number(process.env.REACT_APP_NETWORK_ID),
//           network: process.env.REACT_APP_CHAIN_NAME,
//           rpc: { [process.env.REACT_APP_NETWORK_ID]: process.env.REACT_APP_RPC_NODE_1 }
//         });
  }

  rs.isInstalledWallet = !!web3ProviderSource;
  if (!web3ProviderSource) {
    return rs;
  }

  try {
    await web3ProviderSource.request({ method: 'eth_requestAccounts' });
  } catch (error) {
    console.log('error', error);
    return rs;
  }

  web3Provider = new ethers.providers.Web3Provider(web3ProviderSource);

  const network = await web3Provider.getNetwork();

  if (network.chainId !== Number(process.env.REACT_APP_NETWORK_ID || '0')) {
    if (selectedProvider === 'METAMASK') {
      try {
        await web3ProviderSource.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: process.env.REACT_APP_CHAIN_ID }],
        });
      } catch (error) {
        console.error('Switch Network failed', error);
      }
    }
  } else {
    const signer = web3Provider.getSigner();

    if (signer) {
      rs.walletConnected = await signer.getAddress();
      rs.signer = signer;
    }
  }

  return rs;
}

export default asyncConnectWallet;
