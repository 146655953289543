import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import sportSaleAbi from '../config/abis/sport-sale.json';
import erc20Abi from '../config/abis/erc20.json';
import sportNftAbi from '../config/abis/sport-nft.json';
import { getAddress, multiCall, loadContract } from '../services/utils';

import useAppStore from '../store/use-app-store';

const sportSaleAddress = getAddress('sportSale');
const nftAdminAddress = getAddress('nftAdmin');

const useSportSaleContract = () => {
  const walletConnected = useAppStore((state) => state.walletState.walletConnected);
  const [sportSaleState, setSportSaleState] = useState({
    loading: true,
    packs: [],
    discounts: [],
    nftRemaining: 0,
    startTime: 0,
    started: false,
  });
  const [reload, setReload] = useState(0);

  useEffect(() => {
    const fetchSale = async () => {
      const sportNftContract = loadContract('sportNft', sportNftAbi);
      const nftRemaining = await sportNftContract.balanceOf(nftAdminAddress);

      const usdContract = loadContract('usdc', erc20Abi);
      const decimals = await usdContract.decimals();

      const saleContract = loadContract('sportSale', sportSaleAbi);
      const packsLength = await saleContract.packsLength();
      const discountsLength = await saleContract.discountsLength();
      const startTime = await saleContract.startTime();

      const saleCalls = new Array(packsLength.toNumber()).fill('pack').map((el, i) => ({
        address: sportSaleAddress,
        name: 'packs',
        params: [i],
      }));

      for (let i = 0; new BigNumber(discountsLength._hex).gt(i); i++) {
        saleCalls.push({
          address: sportSaleAddress,
          name: 'discounts',
          params: [i],
        });
      }

      const saleResults = await multiCall(sportSaleAbi, saleCalls);
      const packs = new Array(packsLength.toNumber()).fill('pack').map((el, i) => ({
        id: i,
        maxSellQty: new BigNumber(saleResults[i].maxSellQty._hex).toJSON(),
        price: new BigNumber(saleResults[i].price._hex).div(new BigNumber(10).pow(decimals)).toJSON(),
        qty: new BigNumber(saleResults[i].qty._hex).toJSON(),
      }));
      const discountsOffset = packsLength.toNumber();
      const discounts = new Array(discountsLength.toNumber()).fill('discount').map((el, i) => ({
        id: i,
        per: new BigNumber(saleResults[discountsOffset + i].per._hex).toJSON(),
        qty: new BigNumber(saleResults[discountsOffset + i].qty._hex).toJSON(),
      }));

      setSportSaleState({
        loading: false,
        packs,
        discounts,
        nftRemaining: new BigNumber(nftRemaining._hex).toJSON(),
        startTime: new BigNumber(startTime._hex).toJSON(),
        started: new BigNumber(startTime._hex).times(1000).lt(Date.now()),
      });
    }

    fetchSale();
  }, [walletConnected, setSportSaleState, reload]);

  return {
    walletConnected,
    sportSaleState,
    setReload
  }
}

export default useSportSaleContract;
