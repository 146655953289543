import React from 'react';
import BigNumber from 'bignumber.js';
import { faDiamond, faFutbol } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Countdown from 'react-countdown';

const renderer = ({ days, hours, minutes }) => {
  return (
    <span className="is-flex icon-text is-justify-content-center is-align-items-center">
      <span className="has-text-centered">
        <strong className="is-size-3 has-text-white is-family-secondary">{days}</strong><br />
        <small className="has-text-white">DAYS</small>
      </span>
      <span className="icon mx-5">
        <FontAwesomeIcon icon={faDiamond} size="1x" className="has-text-success" />
      </span>
      <span className="has-text-centered">
        <strong className="is-size-3 has-text-white is-family-secondary">{hours}</strong><br />
        <small className="has-text-white">HOURS</small>
      </span>
      <span className="icon mx-5">
        <FontAwesomeIcon icon={faDiamond} size="1x" className="has-text-success" />
      </span>
      <span className="has-text-centered">
        <strong className="is-size-3 has-text-white is-family-secondary">{minutes}</strong><br />
        <small className="has-text-white">MIMUTES</small>
      </span>
    </span>
  );
};

const CustomCountdown = ({ title, value }) => {
  return (
    <div className="columns is-justify-content-center is-align-items-center">
      <div className="column is-narrow">
        <h3 className="is-size-4 has-text-white has-text-weight-bold has-text-centered is-family-secondary">
          {title}
        </h3>
      </div>
      <div className="column is-narrow is-hidden-mobile">
        <span className="icon is-large">
          <FontAwesomeIcon icon={faFutbol} size="2x" className="has-text-success" />
        </span>
      </div>
      <div className="column is-narrow">
        <Countdown date={new BigNumber(value).times(1000).toNumber()} renderer={renderer} />
      </div>
    </div>
  );
}

export default CustomCountdown;
