import React, { useEffect, useState } from 'react';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactMarkdown from 'react-markdown';

import GameCardNftCard from '../../shared/components/game-card-nft-card';
import Loading from '../../shared/components/loading';
import { getAddress } from '../../services/utils';
import useAppStore from '../../store/use-app-store';
import asyncFetchGameCardsData from '../../blockchain/async-fetch-game-cards-data';

const GameCardPage = ({ id }) => {
  const isOwner = useAppStore((state) => state.gameCardsState.gameCards.some((gameCard) => gameCard.id === Number(id)));

  const [gameCard, setGameCard] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGameCard = async () => {
      const {gameCards} = await asyncFetchGameCardsData([{ id, quantity: 1 }]);
      setGameCard(gameCards[0]);
      setLoading(false);
    }

    fetchGameCard();
  }, [id, setGameCard, setLoading]);

  if (loading) {
    return <Loading />;
  }

  return (
    <main role="main" className="section has-background-light">
      <div className="container">
        {isOwner ? (
          <div className="buttons is-justify-content-end">
            <a href={`https://opensea.io/assets/matic/${getAddress('gameCardNft')}/${gameCard.id}`} target="_blank" rel="noreferrer" className="button is-primary is-rounded is-outlined">
              <span className="icon-text">
                <span>TRADE</span>
                <span className="icon">
                  <FontAwesomeIcon icon={faExternalLink} />
                </span>
              </span>
            </a>
          </div>
        ) : null}
        <div className="columns is-centered">
          <div className="column is-one-third">
            <GameCardNftCard gameCard={gameCard} hideQuantity />
          </div>
          <div className="column is-one-third">
            <h1 className="title">
              {gameCard.name}
            </h1>
            <div className="message is-info">
              <div className="message-body content">
                <ReactMarkdown>{gameCard.description}</ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default GameCardPage;
