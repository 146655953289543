import asyncFetchSports from '../blockchain/async-fetch-sports';
import sportsInitialState from '../states/sports-initial-state';

const createSportsSlice = (set, get) => ({
  sportsState: sportsInitialState,
  loadingSports: true,
  updatingSports: false,
  fetchSports: async (connectedWallet) => {
    if (!get().updatingSports) {
      set((state) => ({ ...state, updatingSports: true }));
      const rs = await asyncFetchSports([...get().sportsState.sports], connectedWallet);
      set((_) => ({sportsState: rs, updatingSports: false}));
      if (get().loadingSports) {
        set((_) => ({loadingSports: false}));
      }
    }
  },
});

export default createSportsSlice;
