import React, { useContext, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { Link, Redirect } from 'wouter';
import confetti from 'canvas-confetti';
import { Link as ScrollLink } from 'react-scroll';

import trainingAbi from '../../config/abis/training.json';
import erc20Abi from '../../config/abis/erc20.json';

import { ToastContext } from '../../context/toast';

import useTrainingContract from '../../hooks/use-training-contract';
import PlayerCard from '../../shared/components/player-card';
import { classNames, getAddress, loadContract } from '../../services/utils';
import SportNftCard from '../../shared/components/sport-nft-card';
import GameCardNftCard from '../../shared/components/game-card-nft-card';
import Loading from '../../shared/components/loading';
import { trainingErrorMessage } from './utils';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

const TrainingPage = ({ tcId }) => {
  const { addToast } = useContext(ToastContext);

  const {
    signer,
    trainingCenter,
    players,
    sportNfts,
    gameCards,
    allowance,
    balance,
    trainingSportItems,
    trainingGameCardItems,
    loading
  } = useTrainingContract(tcId);

  const [tab, setTab] = useState(0);
  const [isPending, setIsPending] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(-1);
  const [selectedSportNfts, setSelectedSportNfts] = useState([]);
  const [selectedGameCards, setSelectedGameCards] = useState([]);
  const [selectedGameCardValues, setSelectedGameCardValues] = useState([]);

  const renderSelectPlayerButton = (playerId) => {
    const text = playerId === selectedPlayer ? 'Selected' : 'Select';

    return (
      <ScrollLink
        to="preview-section"
        smooth={true}
        duration={500}
        className="button is-small is-outlined is-primary is-rounded"
        onClick={() => setSelectedPlayer(playerId)}
        disabled={isPending || (playerId === selectedPlayer)}
      >
        {text}
      </ScrollLink>
    )
  }

  const itemsRemaining = useMemo(() => {
    if (!trainingCenter?.qtyAllow) {
      return 0;
    }

    return Number(trainingCenter?.qtyAllow) -
      (
        selectedSportNfts.length +
        selectedGameCardValues.reduce((sum, n) => sum + n, 0)
      );
  }, [trainingCenter?.qtyAllow, selectedSportNfts, selectedGameCardValues]);

  const toggleSportNft = (sportNftId) => {
    if (selectedSportNfts.includes(sportNftId)) {
      setSelectedSportNfts(prevState => prevState.filter(id => id !== sportNftId));
    } else if (itemsRemaining > 0) {
      setSelectedSportNfts(prevState => [...prevState, sportNftId]);
    }
  }

  const increaseGameCard = (gameCardId) => {
    if (itemsRemaining > 0) {
      if (selectedGameCards.includes(gameCardId)) {
        for (let i = 0; i < selectedGameCards.length; i++) {
          if (gameCardId === selectedGameCards[i]) {
            setSelectedGameCardValues(prevState => prevState.map((val, j) => j === i ? val + 1 : val));
            break;
          }
        }
      } else {
        setSelectedGameCards(prevState => [...prevState, gameCardId]);
        setSelectedGameCardValues(prevState => [...prevState, 1]);
      }
    }
  }

  const decreaseGameCard = (gameCardId) => {
    for (let i = 0; i < selectedGameCards.length; i++) {
      if (gameCardId === selectedGameCards[i]) {
        if (selectedGameCardValues[i] > 1) {
          setSelectedGameCardValues(prevState => prevState.map((val, j) => j === i ? val - 1 : val));
        } else {
          setSelectedGameCards(prevState => prevState.filter((_, j) => j !== i));
          setSelectedGameCardValues(prevState => prevState.filter((_, j) => j !== i));
        }
        break;
      }
    }
  }

  const handleApprove = async () => {
    setIsPending(true);
    let tx;
    try {
      const usdcContract = loadContract('usdc', erc20Abi, signer);
      tx = await usdcContract.approve(getAddress('training'), ethers.constants.MaxUint256);
      await tx.wait();
      addToast('Contract approved successfully!', 'is-success');
    } catch (e) {
      tx = { error: e.data?.message || e.message };
    }

    if(tx.error !== undefined) {
      console.log('error', tx.error);
      addToast('Approve Failed.', 'is-danger');
    }

    setIsPending(false);
  }

  const handleTraining = async () => {
    setIsPending(true);
    let tx;
    let errorMessage = '';
    try {
      const trainingContract = loadContract('training', trainingAbi, signer);
      console.log('training', trainingCenter.id, selectedPlayer, selectedSportNfts, selectedGameCards, selectedGameCardValues);
      tx = await trainingContract.training(trainingCenter.id, selectedPlayer, selectedSportNfts, selectedGameCards, selectedGameCardValues);
      await tx.wait();
      confetti({
        resize: true,
        particleCount: 200,
        startVelocity: 30,
        gravity: 0.5,
        spread: 350,
        origin: {
          x: 0.5,
          y: 0.3,
        },
      });
      addToast('Training Started!', 'is-success');
      setSelectedPlayer(-1);
      setSelectedSportNfts([]);
      setSelectedGameCards([]);
    } catch (error) {
      errorMessage = trainingErrorMessage(error);
    }

    if(errorMessage !== '') {
      console.log('error', errorMessage);
      addToast('Training Failed.', 'is-danger');
    }

    setIsPending(false);
  }

  const renderTrainingOrApproveButton = () => {
    if (new BigNumber(allowance).gt(0)) {
      return (
        <button
          type="button"
          className={classNames('button', 'is-primary', 'is-rounded', 'is-fullwidth', isPending && 'is-loading')}
          disabled={isPending || !selectedPlayer || itemsRemaining > 0 || new BigNumber(balance).lt(trainingCenter.fee)}
          onClick={handleTraining}
        >
          {new BigNumber(balance).lt(trainingCenter.fee) ? 'Insifficient Balance' : 'Start Training!'}
        </button>
      );
    }

    return (
      <button
        type="button"
        className={classNames('button', 'is-primary', 'is-outlined', 'is-rounded', 'is-fullwidth', isPending && 'is-loading')}
        disabled={isPending}
        onClick={handleApprove}
      >
        Approve USDC
      </button>
    );
  }

  const previewData = useMemo(() => {
    let data = [
      ['Defense', 0, 0, 0],
      ['Attack', 0, 0, 0],
      ['Physical', 0, 0, 0],
      ['Morale', 0, 0, 0],
      ['Experience', 0, 0, 0],
    ];
    if (selectedPlayer >= 0) {
      const player = players.find(pl => pl.id === selectedPlayer);
      for (let i = 0; i < 5; i++) {
        data[i][1] = Number(player[data[i][0].toLowerCase()]);
      }
      let increase = [0, 0, 0, 0, 0];
      for (let i = 0; i < selectedSportNfts.length; i++) {
        const sportNft = sportNfts.find(sn => sn.id === selectedSportNfts[i]);
        if (sportNft) {
          const trainingSportItem = trainingSportItems.find(tsi => tsi.rarity === Number(sportNft.rarity));
          if (trainingSportItem) {
            const power = Number(sportNft.power) * Number(trainingSportItem.power) / 10000;
            for (let j = 0; j < 5; j++) {
              let offset = j;
              if (trainingSportItem.rarity === '3' && ['Forward', 'Midfielder'].includes(player.position) && [0, 1].includes(offset)) {
                offset = offset === 1 ? 0 : 1;
              }

              const value = power * Number(trainingSportItem.distribution[offset]) / 10000;
              increase[j] += Number(trainingSportItem.distribution[offset]) > 0 && value < 1 ? 1 : parseInt(value.toString());
            }
          }
        }
      }
      for (let i = 0; i < selectedGameCards.length; i++) {
        const gameCardItem = trainingGameCardItems.find(gci => gci.id === selectedGameCards[i]);
        if (gameCardItem) {
          for (let j = 0; j < 5; j++) {
            increase[j] += (gameCardItem.distribution[j] * selectedGameCardValues[i]);
          }
        }
      }
      for (let i = 0; i < 5; i++) {
        if (data[i][1] + increase[i] > 0) {
          data[i][3] = data[i][1] + increase[i];
        } else {
          data[i][3] = 0;
        }
        data[i][2] = data[i][3] - data[i][1];
      }
    }

    return data;
  }, [
    selectedPlayer,
    players,
    selectedSportNfts,
    sportNfts,
    trainingSportItems,
    trainingGameCardItems,
    selectedGameCards,
    selectedGameCardValues
  ]);

  const renderGameCard = (gameCard) => {
    const index = selectedGameCards.indexOf(Number(gameCard.id));
    const value = index >= 0 ? selectedGameCardValues[index] : 0;

    return (
      <div key={`game-card-${gameCard.id}`} className="column is-one-quarter">
        <GameCardNftCard gameCard={gameCard}>
          <div className="card-content">
            <div className="field has-addons has-addons-right">
              <p className="control">
                <ScrollLink
                  to="preview-section"
                  smooth={true}
                  duration={500}
                  className="button is-primary"
                  onClick={(evt) => { evt.preventDefault(); decreaseGameCard(Number(gameCard.id))} }
                  disabled={isPending || value === 0}
                >
                  <span className="icon-text">
                    <span className="icon">
                      <FontAwesomeIcon icon={faMinus} />
                    </span>
                  </span>
                </ScrollLink>
              </p>
              <p className="control">
                <input className="input has-text-centered" type="text" readOnly value={value} />
              </p>
              <p className="control">
                <ScrollLink
                  to="preview-section"
                  smooth={true}
                  duration={500}
                  className="button is-primary"
                  onClick={(evt) => { evt.preventDefault(); increaseGameCard(Number(gameCard.id))} }
                  disabled={isPending || itemsRemaining < 1 || value >= Number(gameCard.quantity)}
                >
                  <span className="icon-text">
                    <span className="icon">
                      <FontAwesomeIcon icon={faPlus} />
                    </span>
                  </span>
                </ScrollLink>
              </p>
            </div>
          </div>
        </GameCardNftCard>
      </div>
    );
  }

  if (loading) {
    return <Loading />;
  }

  if (!trainingCenter) {
    return <Redirect to="/trainings" />;
  }

  return (
    <>
      <header id="preview-section" className="hero is-dark"  style={{ backgroundImage: 'url(/images/banner.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <div className="hero-body has-text-centered">
          <div className="container">
            <h1 className="title has-text-light">Training Center</h1>
          </div>
        </div>
      </header>
      <main role="main" className="section has-background-white is-clipped">
        <div className="container">
          <div className="columns">
            <div className="column">
              <article className="message is-success">
                <div className="message-body content">
                  <h4>{trainingCenter?.title}</h4>
                  <ul>
                    <li>Enhance your player by training.</li>
                    <li>Strategize your Game with Cards.</li>
                  </ul>
                </div>
              </article>
              <article className="message is-info">
                <div className="message-body content">
                  <h4>How to enhance</h4>
                  <ul>
                    <li>Select 1 player and <strong>{trainingCenter?.qtyAllow}</strong> sport NFT or <strong>{trainingCenter?.qtyAllow}</strong> Training Card.</li>
                    <li>Sport NFTs and Cards can be used once.</li>
                    <li>Sport NFT remains in your wallet after training is done. It is collectible.</li>
                    <li>Training Card don't remain in your wallet after training. It is burneable.</li>
                    <li>Enhancement fee: <strong>{new BigNumber(trainingCenter?.fee).dividedBy(new BigNumber(10).pow(6)).toFormat()} USDC</strong></li>
                  </ul>
                </div>
              </article>
            </div>
            <div className="column">
              <div className="tags mb-0">
                {selectedPlayer >= 0 ? (
                  <span className="tag is-rounded is-medium is-primary">
                    Player [{selectedPlayer}]
                    <button
                      className="delete is-small"
                      onClick={() => setSelectedPlayer(-1)}
                    ></button>
                  </span>
                ) : (
                  <ScrollLink
                    to="tabs-section"
                    className="tag is-medium is-rounded border-dashed"
                    onClick={() => setTab(0)}
                    smooth={true}
                    duration={500}
                  >
                    Select Player
                  </ScrollLink>
                )}
                {selectedSportNfts.map(sn => (
                  <span key={`selected-sport-nft-${sn}`} className="tag is-rounded is-medium is-success">
                    Sport [{sn}]
                    <button
                      className="delete is-small"
                      onClick={() => toggleSportNft(sn)}
                    />
                  </span>
                ))}
                {selectedGameCards.map((gameCardId, i) => (
                  <span key={`selected-game-card-${gameCardId}`} className="tag is-rounded is-medium is-info">
                    Training Card [{gameCardId}] ({selectedGameCardValues[i]})
                  </span>
                ))}
                {itemsRemaining > 0 ? (
                  <ScrollLink
                    to="tabs-section"
                    className="tag is-medium is-rounded border-dashed"
                    onClick={() => setTab(1)}
                    smooth={true}
                    duration={500}
                  >
                    Select {itemsRemaining} Item{itemsRemaining > 1 ? 's' : ''}
                  </ScrollLink>
                ) : null}
              </div>
              <table className="table is-fullwidth mb-2">
                <thead>
                  <tr>
                    <th className="has-background-dark has-text-light">Skill</th>
                    <th className="has-background-dark has-text-light has-text-right">Value</th>
                    <th className="has-background-dark has-text-light has-text-right">Boost</th>
                    <th className="has-background-dark has-text-light has-text-right">After Training</th>
                  </tr>
                </thead>
                <tbody>
                  {previewData && previewData.map((row, i) => (
                    <tr key={`table-row-${i}`}>
                      {row.map((cell, j) => (
                        <td
                          key={`table-cell-${i}-${j}`}
                          className={classNames(
                            j > 0 && 'has-text-right',
                            j === 2 && cell !== 0 && (cell < 0 ? 'has-text-danger' : 'has-text-success'),
                            j === 3 && cell !== row[1] && (cell < row[1] ? 'has-text-danger' : 'has-text-success'),
                            j === 3 && 'has-background-warning-light is-size-5'
                          )}
                        >
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              {renderTrainingOrApproveButton()}
            </div>
          </div>
          <nav id="tabs-section" className="tabs is-boxed is-centered mt-5">
            <ul>
              <li className={classNames(tab === 0 && 'is-active')}>
                <a href="/" onClick={(evt) => { evt.preventDefault(); setTab(0)}}>Players ({players.length})</a>
              </li>
              <li className={classNames(tab === 1 && 'is-active')}>
                <a href="/" onClick={(evt) => { evt.preventDefault(); setTab(1)}}>Sports ({sportNfts.length})</a>
              </li>
              <li className={classNames(tab === 2 && 'is-active')}>
                <a href="/" onClick={(evt) => { evt.preventDefault(); setTab(2)}}>Training Cards ({gameCards.length})</a>
              </li>
            </ul>
          </nav>
          <div className={classNames('columns', 'is-centered', 'is-multiline', tab !== 0 && 'is-hidden')}>
            {players.length === 0 ? (
              <div className="column is-half">
                <article className="message is-warning">
                  <div className="message-body">
                    Your bucket is empty. <Link to="/sale">Buy here</Link> and became a superstar!
                  </div>
                </article>
              </div>
            ) : null}
            {players.map(player => (
              <div key={`players-${player.id}`} className="column is-one-quarter">
                <div className="is-flex is-justify-content-space-between is-align-items-center mb-3">
                  <h3 className="subtitle has-text-centered mb-0">{player.name}</h3>
                  {renderSelectPlayerButton(player.id)}
                </div>
                <PlayerCard player={player} />
              </div>
            ))}
          </div>
          <div className={classNames('columns', 'is-centered', 'is-multiline', tab !== 1 && 'is-hidden')}>
            {sportNfts.length === 0 ? (
              <div className="column is-half">
                <article className="message is-warning">
                  <div className="message-body">
                    Your bucket is empty. <Link to="/sport-sale">Buy here</Link> and became a superstar!
                  </div>
                </article>
              </div>
            ) : null}
            {sportNfts.map(sportNft => (
              <div key={`sports-${sportNft.id}`} className="column is-one-quarter">
                <SportNftCard sportNft={sportNft}>
                  <div className="card-content">
                    <ScrollLink
                      to="preview-section"
                      smooth={true}
                      duration={500}
                      className={classNames('button', 'is-rounded', 'is-fullwidth', 'is-outlined', selectedSportNfts.includes(sportNft.id) ? 'is-success' : 'is-primary')}
                      onClick={() => toggleSportNft(sportNft.id) }
                      disabled={isPending || (!selectedSportNfts.includes(sportNft.id) && itemsRemaining === 0)}
                    >
                      <span className="icon-text">
                        <span className="icon">
                          <FontAwesomeIcon icon={selectedSportNfts.includes(sportNft.id) ? faSquareCheck : faSquare} />
                        </span>
                        <span>{selectedSportNfts.includes(sportNft.id) ? 'Selected' : 'Select'}</span>
                      </span>
                    </ScrollLink>
                  </div>
                </SportNftCard>
              </div>
            ))}
          </div>

          <div className={classNames('columns', 'is-centered', 'is-multiline', tab !== 2 && 'is-hidden')}>
            {gameCards.length === 0 ? (
              <div className="column is-half">
                <article className="message is-warning">
                  <div className="message-body">
                    Your bucket is empty. <Link to="/game-card-sale">Buy here</Link> and became a superstar!
                  </div>
                </article>
              </div>
            ) : null}
            {gameCards.map(gameCard => renderGameCard(gameCard))}
          </div>
        </div>
      </main>
    </>
  );
}

export default TrainingPage;
