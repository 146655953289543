import React from 'react';

const Loading = ({ title = 'Loading...' }) => {
  return (
    <div className="pageloader is-active" style={{ backgroundImage: 'url(/images/hero.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      <span className="title">{title}</span>
    </div>
  );
}

export default Loading;
