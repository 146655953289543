import asyncFetchMatches from '../blockchain/async-fetch-matches';
import matchesInitialState from '../states/matches-initial-state';

const createMatchesSlice = (set, get) => ({
  matchesState: matchesInitialState,
  loadingMatches: true,
  updatingMatches: false,
  fetchMatches: async (connectedWallet) => {
    if (!get().updatingMatches) {
      set((state) => ({ ...state, updatingMatches: true }));
      const rs = await asyncFetchMatches([...get().matchesState.matches], connectedWallet);
      set(() => ({matchesState: rs, loadingMatches: false, updatingMatches: false}));
    }
  },
  setUserClaimedAmount: (pid) => {
    set((state) => ({
      matchesState: {
        matches: state.matchesState.matches.map((match) => {
          if (match.pid === pid && match.userPendingReward !== '0') {
            return {...match, userClaimedAmount: match.userPendingReward}
          }

          return {...match};
        })
      }
    }));
  },
});

export default createMatchesSlice;
