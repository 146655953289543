import BigNumber from 'bignumber.js';
import { useState } from 'react';
import useWorldCupGameContract from '../../hooks/use-world-cup-game-contract';
import Loading from '../../shared/components/loading';
import BetOnMatchModal from './components/bet-on-match-modal';
import AddPlayersModal from './components/add-players-modal';
import Filters from './components/filters';
import MatchRow from './components/match-row';
import WorldCupNav from '../../shared/components/world-cup-nav';

const filters = JSON.parse(localStorage.getItem('worldCupFilters'));

const WorldCupGamePage = () => {
  const {matches, loading} = useWorldCupGameContract();

  const [betOnMatch, setBetOnMatch] = useState(null);
  const [addPlayersOnMatch, setAddPlayersOnMatch] = useState(null);

  const [filter, setFilter] = useState(filters ? filters.filter : 'active');
  const [stakedOnly, setStakedOnly] = useState(filters ? filters.stakedOnly : false);
  const [pendingClaim, setPendingClaim] = useState(filters ? filters.pendingClaim : false);
  const [search, setSearch] = useState(filters ? filters.search : '');

  const handleBetOnMatchModalClose = () => {
    setBetOnMatch(null);
  }

  const handleAddPlayersModalClose = () => {
    setAddPlayersOnMatch(null);
  }

  const matchesBy = (applyFilter) => {
    let rs = matches;

    if (search !== '') {
      const q = search.toLowerCase();
      rs = rs.filter((match) => {
        return match.localTeamName.toLowerCase().includes(q) ||
          match.visitorTeamName.toLowerCase().includes(q) ||
          match.localTeamShortName.toLowerCase().includes(q) ||
          match.visitorTeamShortName.toLowerCase().includes(q)
      });
    }

    if (stakedOnly) {
      rs = rs.filter((match) => new BigNumber(match.userLocalWinAmount).gt(0) || new BigNumber(match.userVisitorWinAmount).gt(0) || new BigNumber(match.userTieAmount).gt(0));
    }

    if (pendingClaim) {
      rs = rs.filter((match) => new BigNumber(match.userPendingReward).gt(0));
    }

    if (applyFilter === 'active') {
      rs = rs.filter((match) => !match.finished);
    }

    if (applyFilter === 'finished') {
      rs = rs.filter((match) => match.finished);
    }

    if (applyFilter === 'playing') {
      rs = rs.filter((match) => match.playingNow);
    }

    return rs;
  }

  const renderMatches = () => {
    return matchesBy(filter).map((match) => <MatchRow key={`match-${match.pid}`} match={match} setBetOnMatch={setBetOnMatch} setAddPlayersOnMatch={setAddPlayersOnMatch} />);
  }

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div
        style={{
          backgroundImage: 'url(/images/hero.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          zIndex: '-1'
        }}
      />
      <WorldCupNav tab="game">
        <p className="title has-text-light">Bet on World Cup Matches and Earn Big Prices!</p>
        <p className="subtitle">Enhance your bets using Smart NFTs</p>
        <p className="is-italic mb-0">Are you new? don't worry, <a href="https://docs.degoverse.bet/guides/betting-on-the-platform" className="has-text-success" target="_blank" rel="noreferrer">click here</a> to see how to play</p>
      </WorldCupNav>
      <main role="main" className="section is-clipped">
        <div className="container">
          <Filters
            filter={filter}
            setFilter={setFilter}
            stakedOnly={stakedOnly}
            pendingClaim={pendingClaim}
            setStakedOnly={setStakedOnly}
            setPendingClaim={setPendingClaim}
            search={search}
            setSearch={setSearch}
          />
          {renderMatches()}
        </div>
      </main>
      {betOnMatch && <BetOnMatchModal match={betOnMatch} handleModalClose={handleBetOnMatchModalClose} />}
      {addPlayersOnMatch && <AddPlayersModal match={addPlayersOnMatch} handleModalClose={handleAddPlayersModalClose} />}
    </>
  );
}

export default WorldCupGamePage;
