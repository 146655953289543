import useLocation from '../../../hooks/use-location';
import { classNames } from '../../../services/utils';

const rarityNames = ['-', 'Common', 'Epic', 'Legendary', 'Rare', 'Golden'];

const SportNftCard = ({ sportNft, children = null, navigate = false }) => {
  const [, setLocation, ] = useLocation();

  console.log('sportNft', sportNft)

  return (
    <div
      className={classNames('card', 'mb-3', navigate && 'is-clickable')}
      onClick={() => navigate && setLocation(`/sports/${sportNft.id}`)}
    >
      <div className="card-image">
        <figure className="image">
          <img src={sportNft.image} alt={sportNft.name} />
        </figure>
      </div>
      <div className="card-content">
        <h3 className="subtitle has-text-centered">{sportNft.name} [{sportNft.id}]</h3>
        <div className="field is-grouped is-grouped-multiline is-justify-content-center">
          <div className="control">
            <div className="tags has-addons">
              <span className="tag">Rarity</span>
              <span className="tag is-info">{rarityNames[sportNft.rarity]}</span>
            </div>
          </div>
          <div className="control">
            <div className="tags has-addons">
              <span className="tag">Power</span>
              <span className="tag is-success">{sportNft.power}</span>
            </div>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
}

export default SportNftCard;
