import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';

const abi = [
  'error InvalidBatchLength()',
  'error InvalidTime()',
  'error DepositFeeTooHigh()',
  'error InvalidAmount()',
  'error NotOwner()',
  'error InvalidAddress()',
  'error InvalidClaim()',
  'error MatchAlreadyEnded()',
  'error InvalidBetOption()',
  'error TreasuryAlreadyClaimed(uint256 pid)',
  'error NothingToClaim()',

  'error TrainingCenterUnavailable(uint256 trainingCenterId)',
  'error ItemsQuantityNotAllowed(uint256 expected, uint256 qty)',
  'error InsufficientBalance(uint256 available, uint256 required)',
  'error TokenOwnership(address tokenAddress, uint256 tokenId, address userAddress)',
  'error SportNftAlreadyUsed(uint256 sportNftId)',
  'error SportItemUnavailable(uint256 rarity)',
  'error SportItemAlreadyExist(uint256 rarity)',
  'error RarityUnavailable()',
  'error GameCardItemUnavailable(uint256 id)',
  'error GameCardItemAlreadyExist(uint256 id)',
  'error GameCardIdUnavailable()',
  'error NumberOutOfRange(uint256 num, uint256 from, uint256 to)'
];

export const trainingErrorMessage = (error) => {
  if (typeof error.data === 'undefined') {
    return error.message;
  }

  const int = new ethers.utils.Interface(abi);
  const error_data = error.data.data;

  try {
    const decoded = int.getError(error_data.substring(0, 10), error_data);
    const errorResult = int.decodeErrorResult(error_data.substring(0, 10), error_data);

    const params = decoded.inputs.map(input => {
      if (input.type === 'uint256') {
        return input.name +' = '+ new BigNumber(errorResult[input.name]._hex).toFormat();
      }

      return input.name +' = '+ errorResult[input.name];
    });

    return `${decoded.name}(${params.join(', ')})`;
  } catch (e) {
    console.log('e', e);
  }

  return error.data?.message || error.message;
}
