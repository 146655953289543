import create from 'zustand';

import createWalletSlice from './create-wallet-slice';
import createMatchesSlice from './create-matches-slice';
import createTrainingSlice from './create-training-slice';
import createPlayersSlice from './create-players-slice';
import createSportsSlice from './create-sports-slice';
import createGameCardsSlice from './create-game-cards-slice';
// import createPurchasesSlice from './create-purchases-slice';
// import createSaleSlice from './create-sale-slice';

const useAppStore = create((...a) => ({
  ...createWalletSlice(...a),
  ...createMatchesSlice(...a),
  ...createTrainingSlice(...a),
  ...createPlayersSlice(...a),
  ...createSportsSlice(...a),
  ...createGameCardsSlice(...a),
  // ...createPurchasesSlice(...a),
  // ...createSaleSlice(...a),
}));

export default useAppStore;
