import worldCupAbi from '../config/abis/world-cup.json';
import erc20Abi from '../config/abis/erc20.json';
import { getAddress, loadContract, multiCall } from '../services/utils';

import fixturesJson from '../db/fixtures.json';

const worldCupAddress = getAddress('worldCup');

const teamStatuses = {'NS': 'Not Started', 'LIVE': 'Live', 'HT': 'Half-Time', 'FT': 'Full-Time', 'ET': 'Extra-Time', 'PEN_LIVE': 'Penalty Shootout', 'AET': 'Finished after extra time', 'BREAK': 'Regular time finished', 'FT_PEN': 'Full-Time after penalties', 'CANCL': 'Canceled', 'POSTP': 'PostPoned', 'INT': 'Interrupted', 'ABAN': 'Abandoned', 'SUSP': 'Suspended', 'TBA': 'To Be Announced', 'AWARDED': 'Awarded', 'DELAYED': 'Delayed', 'WO': 'Walk Over', 'AU': 'Awaiting Updates', 'Deleted': 'Deleted'};

const teamNames = ['-', 'Argentina','Belgium','Brazil','Cameroon','Canada','Croatia','Denmark','Ecuador','England','France','Germany','Ghana','Iran','Japan','Mexico','Morocco','Netherlands','Poland','Portugal','Qatar','Saudi Arabia','Senegal','Serbia','South Korea','Spain','Switzerland','Tunisia','Uruguay','USA','Costa Rica','Australia','Wales'];
const teamShortNames = ['-','ARG','BEL','BRA','CMR','CAN','CRO','DEN','ECU','ENG','FRA','GER','GHA','IRN','JPN','MEX','MAR','NED','POL','POR','QAT','KSA','SEN','SRB','KOR','ESP','SUI','TUN','URU','USA','CRC','AUS','WAL'];
const groupNames = {'246691': 'Group A','246692': 'Group B','246693': 'Group C','246694': 'Group D','246695': 'Group E','246696': 'Group F','246697': 'Group G','246698': 'Group H'};

const asyncFetchMatches = async (prevMatches, walletConnected) => {
  const matches = [...prevMatches];
  const usedPlayerIds = [];
  const now = parseInt(Date.now() / 1_000);

  try {

    const worldCupContract = loadContract('worldCup', worldCupAbi);
    const matchesLength = await worldCupContract.matchesLength();
    const paused = await worldCupContract.paused();
  
    // load match data
    const matchDataCalls = [];
    for (let i = prevMatches.length; matchesLength.gt(i); i++) {
      matchDataCalls.push({
        name: 'matchData',
        address: worldCupAddress,
        params: [i]
      });
    }
    if (matchDataCalls.length > 0) {
      const matchDataRs = await multiCall(worldCupAbi, matchDataCalls);
    
      for (let i = 0; i < matchDataRs.length; i++) {
        matches.push({
          pid: prevMatches.length + i,
          localTeamId: matchDataRs[i][0].toNumber(),
          localTeamName: teamNames[matchDataRs[i][0].toNumber()],
          localTeamShortName: teamShortNames[matchDataRs[i][0].toNumber()],
          visitorTeamId: matchDataRs[i][1].toNumber(),
          visitorTeamName: teamNames[matchDataRs[i][1].toString()],
          visitorTeamShortName: teamShortNames[matchDataRs[i][1].toNumber()],
          startTime: matchDataRs[i][2].toNumber(),
          treasuryFee: matchDataRs[i][3].toNumber(),
          experienceRate: matchDataRs[i][4].toNumber(),
          stakedToken: matchDataRs[i][5],
          matchType: matchDataRs[i][6],
  
          userLocalWinAmount: '0',
          userLocalWinPowerAmount: '0',
          userLocalWinPlayerIds: [],
          userVisitorWinAmount: '0',
          userVisitorWinPowerAmount: '0',
          userVisitorWinPlayerIds: [],
          userTieAmount: '0',
          userTiePowerAmount: '0',
          userTiePlayerIds: [],
          userClaimedAmount: '0',
          userPendingReward: '0',
  
          paused
        });
      }
    }
  
    // load match state
    const matchStateCalls = [];
    for (let i = 0; matchesLength.gt(i); i++) {
      matchStateCalls.push({
        name: 'matchStates',
        address: worldCupAddress,
        params: [i]
      });
    }
    const matchStateRs = await multiCall(worldCupAbi, matchStateCalls);
  
    for (let i = 0; matchesLength.gt(i); i++) {
      matches[i] = Object.assign({}, prevMatches[i] || {}, matches[i], {
        totalAmount: matchStateRs[i][0].toString(),
        localWinAmount: matchStateRs[i][1].toString(),
        localWinPowerAmount: matchStateRs[i][2].toString(),
        visitorWinAmount: matchStateRs[i][3].toString(),
        visitorWinPowerAmount: matchStateRs[i][4].toString(),
        tieAmount: matchStateRs[i][5].toString(),
        tiePowerAmount: matchStateRs[i][6].toString(),
        bet: matchStateRs[i][7],
        claimedTreasury: matchStateRs[i][8].toString(),
  
        playingNow: matches[i].startTime < now && matchStateRs[i][7] === 0,
        finished: matchStateRs[i][7] !== 0,
      });
    }
  
    const usedPlayersCalls = [];
    for (let i = 0; matchesLength.gt(i); i++) {
      usedPlayersCalls.push({
        name: 'getUsedPlayerIds',
        address: worldCupAddress,
        params: [i]
      });
    }
    const usdPlayersRs = await multiCall(worldCupAbi, usedPlayersCalls);
    for (let i = 0; i < usdPlayersRs.length; i++) {
      for (let j = 0; j < usdPlayersRs[i][0].length; j++) {
        usedPlayerIds.push(usdPlayersRs[i][0][j].toString());
      }
    }
  
    // ERC20 data
    const erc20Calls = [];
    const mapErc20Ids = [];
    for (let match of matches) {
      if (match.tokenSymbol === undefined && match.tokenDecimals === undefined) {
        erc20Calls.push(
          {
            name: 'symbol',
            address: match.stakedToken
          },
          {
            name: 'decimals',
            address: match.stakedToken
          }
        );
        mapErc20Ids.push(match.pid);
      }
    }
    if (erc20Calls.length > 0) {
      const erc20Rs = await multiCall(erc20Abi, erc20Calls);
      for (let i = 0; i < mapErc20Ids.length; i++) {
        let j = i * 2;
        matches[mapErc20Ids[i]].tokenSymbol = erc20Rs[j][0];
        matches[mapErc20Ids[i]].tokenDecimals = erc20Rs[j + 1][0];
      }
    }
  
    if (walletConnected) {
      const rewardsCalls = [];
      const ids = [];
      for (let i = 0; i < matches.length; i++) {
        if (matches[i].bet !== 0) {
          rewardsCalls.push({
            name: 'pendingReward',
            address: worldCupAddress,
            params: [i, walletConnected]
          });
          ids.push(i);
        }
      }
      if (rewardsCalls.length > 0) {
        const rewardsRs = await multiCall(worldCupAbi, rewardsCalls);
        for (let i = 0; i < rewardsRs.length; i++) {
          matches[ids[i]].userPendingReward = rewardsRs[i].toString();
        }
      }
  
      const betCalls = [];
      const playersCalls = [];
      // bet Options 1, 2, 3 (only matchType === 0)
      for (let match of matches) {
        for (let j = 1; j <= (match.matchType === 0 ? 3 : 2); j++) {
          betCalls.push({
            name: 'betData',
            address: worldCupAddress,
            params: [match.pid, walletConnected, j]
          });
          playersCalls.push({
            name: 'getPlayerIds',
            address: worldCupAddress,
            params: [match.pid, walletConnected, j]
          });
        }
      }
      let betIndex = 0;
      let playerIndex = 0;
  
      const betRs = await multiCall(worldCupAbi, betCalls);
      const playersRs = await multiCall(worldCupAbi, playersCalls);
  
      for (let match of matches) {
        const localWinData = betRs[betIndex];
        if (localWinData[0].gt(0)) {
          match.userClaimedAmount = localWinData[0].toString();
        }
        match.userLocalWinAmount = localWinData[1].toString();
        match.userLocalWinPowerAmount = localWinData[2].toString();
        betIndex += 1;
  
        const visitorWinData = betRs[betIndex];
        if (visitorWinData[0].gt(0)) {
          match.userClaimedAmount = visitorWinData[0].toString();
        }
        match.userVisitorWinAmount = visitorWinData[1].toString();
        match.userVisitorWinPowerAmount = visitorWinData[2].toString();
        betIndex += 1;
  
        if (match.matchType === 0) {
          const tieData = betRs[betIndex];
          if (tieData[0].gt(0)) {
            match.userClaimedAmount = tieData[0].toString();
          }
          match.userTieAmount = tieData[1].toString();
          match.userTiePowerAmount = tieData[2].toString();
          betIndex += 1;
        }
  
        const localWinPlayers = playersRs[playerIndex];
        match.userLocalWinPlayerIds = localWinPlayers[0].map((playerId) => playerId.toString());
        playerIndex += 1;
  
        const visitorWinPlayers = playersRs[playerIndex];
        match.userVisitorWinPlayerIds = visitorWinPlayers[0].map((playerId) => playerId.toString());
        playerIndex += 1;
  
        if (match.matchType === 0) {
          const tiePlayers = playersRs[playerIndex];
          match.userTiePlayerIds = tiePlayers[0].map((playerId) => playerId.toString());
          playerIndex += 1;
        }
      }
    }
  
    if (
      matches.length !== prevMatches.length ||
      matches.some((match) => match.playingNow)
    ) {
      // Merge json
      for (let match of matches) {
        const fixture = fixturesJson.find(fix => fix.localTeamId === match.localTeamId && fix.visitorTeamId === match.visitorTeamId && fix.startTime === match.startTime);
        if (fixture) {
          match.status = teamStatuses[fixture.status];
          match.localTeamScore = fixture.localTeamScore;
          match.visitorTeamScore = fixture.visitorTeamScore;
          match.localTeamPenScore = fixture.localTeamPenScore;
          match.visitorTeamPenScore = fixture.visitorTeamPenScore;
          match.groupName = groupNames[fixture.groupId];
          match.timeMinute = fixture.timeMinute;
          match.timeSecond = fixture.timeSecond;
          match.timeAddedTime = fixture.timeAddedTime;
          match.timeExtraMinute = fixture.timeExtraMinute;
          match.timeInjuryTime = fixture.timeInjuryTime;
        }
      }
    }
  } catch (error) {
    console.log('error', error);
  }

  // let sum = 0;
  // for (let match of matches) {
  //   sum += Number(match.claimedTreasury);
  // }
  // console.log('sum', sum);
  // console.log('time lapse', (Date.now() / 1000) - now);

  return {matches, usedPlayerIds};
}

export default asyncFetchMatches;
