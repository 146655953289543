export const getPowerBoost = (player) => {
  const defense = Number(player.defense);
  const attack = Number(player.attack);
  const physical = Number(player.physical);
  const morale = Number(player.morale);
  const experience = Number(player.experience);
  const position = player.position;

  let defenseFactor = 1;
  let attackFactor = 1;
  if (position === 3) {
    defenseFactor = 0.75;
    attackFactor = 0.75;
  } else if (position === 2 || position === 1) {
    attackFactor = 0.5;
  } else if (position === 4) {
    defenseFactor = 0.5;
  }

  const physicalFactor = 0.5;
  const moraleFactor = 0.1;
  const experienceFactor = 0.05;

  return (((defense * defenseFactor)
    + (attack * attackFactor)
    + (physical * physicalFactor)
    + (morale * moraleFactor)
    + (experience * experienceFactor)) / 100).toFixed(2);
}
