import asyncFetchGameCards from '../blockchain/async-fetch-game-cards';
import gameCardsInitialState from '../states/game-cards-initial-state';

const createGameCardsSlice = (set, get) => ({
  gameCardsState: gameCardsInitialState,
  loadingGameCards: true,
  updatingGameCards: false,
  fetchGameCards: async (connectedWallet) => {
    if (!get().updatingGameCards) {
      set((state) => ({ ...state, updatingGameCards: true }));
      const rs = await asyncFetchGameCards([...get().gameCardsState.gameCards], connectedWallet);
      set((_) => ({gameCardsState: rs, loadingGameCards: false, updatingGameCards: false}));
    }
  },
});

export default createGameCardsSlice;
