import { useRef, useEffect } from 'react';

const usePollingEffect = (
  asyncCallback,
  dependencies = [],
  interval = 10_000, // 10 seconds,
  onCleanUp = () => {}
) => {
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    let _stopped = false;
    (async function pollingCallback() {
      try {
        await asyncCallback();
      } finally {
        // Set timeout after it finished, unless stopped
        timeoutIdRef.current = !_stopped && setTimeout(
          pollingCallback,
          interval
        );
      }
    })()
    // Clean up if dependencies change
    return () => {
      _stopped = true; // prevent racing conditions
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      onCleanUp();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, interval]);
}

export default usePollingEffect;
