import React, { useEffect, useState, useRef, useMemo } from 'react';
import { request, gql } from 'graphql-request'

import BigNumber from 'bignumber.js';
import { Link } from 'wouter';
import { classNames } from '../../services/utils';
import useLocation from '../../hooks/use-location';

const useQuery = () => {
  const [, , search] = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const ReferralsPage = () => {
  const [referrer, setReferrer] = useState('');
  const [purchases, setPurchases] = useState([]);
  const [sportPurchases, setSportPurchases] = useState([]);
  const [gameCardPurchases, setGameCardPurchases] = useState([]);
  const [pending, setPending] = useState(false);
  const [, setLocation, ] = useLocation();
  const query = useQuery();
  const inputRef = useRef(null);

  useEffect(() => {
    const fetchHome = async () => {
      if (!referrer) {
        setPurchases([]);
        return;
      }

      setPending(true);
      const queryPurchases = gql`
        {
          purchases(where: {referrer: "${referrer}"}) {
            id
            saleQty
            amountUsd
            purchasedAtTimestamp
            discount {
              per
            }
            salePack {
              id
              qty
              price
            }
          }
          sportPurchases(where: {referrer: "${referrer}"}) {
            id
            saleQty
            amountUsd
            purchasedAtTimestamp
            discount {
              per
            }
            salePack {
              id
              qty
              price
            }
          }
          gameCardPurchases(where: {referrer: "${referrer}"}) {
            id
            saleQty
            amountUsd
            purchasedAtTimestamp
            discount {
              per
            }
            salePack {
              id
              qty
              price
            }
          }
        }
      `;

      try {
        const rs = await request(process.env.REACT_APP_GRAPHQL_URL, queryPurchases);

        if (rs.purchases?.length > 0) {
          setPurchases(rs.purchases);
        } else {
          setPurchases([]);
        }
        if (rs.sportPurchases?.length > 0) {
          setSportPurchases(rs.sportPurchases);
        } else {
          setSportPurchases([]);
        }
        if (rs.gameCardPurchases?.length > 0) {
          setGameCardPurchases(rs.gameCardPurchases);
        } else {
          setGameCardPurchases([]);
        }
      } catch(error) {
        console.log(error);
      };
      setPending(false);
    }

    // const interval = setInterval(fetchHome, 10000);

    fetchHome();

    // return () => clearInterval(interval);
  }, [referrer, setPurchases, setSportPurchases, setGameCardPurchases, setPending]);

  useEffect(() => {
    const q = query.get('q');
    if (q) {
      setReferrer(q);
      inputRef.current.value = q;
    }
  }, [query, setReferrer]);

  const handleSearch = () => {
    setLocation(`/referrals?q=${inputRef.current.value}`)
  }

  const renderResults = () => {
    return (
      <>
        <header className="hero is-dark"  style={{ backgroundImage: 'url(/images/banner.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
          <div className="hero-body has-text-centered">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-half">
                  <div className="field has-addons">
                    <div className="control is-expanded">
                      <input
                        className="input has-text-right is-large is-rounded"
                        type="text"
                        placeholder="Enter Referrer Code"
                        ref={inputRef}
                      />
                    </div>
                    <div className="control">
                      <button
                        type="button"
                        className={classNames('button', 'is-large', 'is-primary', 'is-rounded', pending && 'is-loading')}
                        disabled={pending}
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <main role="main" className="section">
          <div className="divider is-size-4">
            Referred Purchases ({purchases.length}) - {referrer}
          </div>
          <div className="columns is-centered">
            <div className="column is-half">
              {(purchases.length + sportPurchases.length + gameCardPurchases.length) === 0 ? (
                <article className="message is-warning">
                  <div className="message-body">
                    Purchase list is empty.
                  </div>
                </article>
              ) : (
                <table className="table is-fullwidth">
                  <thead>
                    <tr>
                    <th className="has-background-dark has-text-light ">Type</th>
                      <th className="has-background-dark has-text-light ">TX</th>
                      <th className="has-background-dark has-text-light has-text-right">Qty</th>
                      <th className="has-background-dark has-text-light has-text-right">Amount</th>
                      <th className="has-background-dark has-text-light has-text-right">Purchased At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchases.map(purchase => (
                      <tr key={`purchase-${purchase.id}`}>
                        <td><span className="has-text-success">Player</span></td>
                        <td>
                          <Link to={`/purchases/${purchase.id}`}>
                            {`${purchase.id.slice(0, 4)}...${purchase.id.slice(purchase.id.length - 4)}`}
                          </Link>
                        </td>
                        <td className="has-text-right">{purchase.saleQty}</td>
                        <td className="has-text-right">
                          ${new BigNumber(purchase.amountUsd).dividedBy(new BigNumber(10).pow(6)).toFormat(2)}
                        </td>
                        <td className="has-text-right">
                          {new Date(purchase.purchasedAtTimestamp * 1000).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' })}
                        </td>
                      </tr>
                    ))}
                    {sportPurchases.map(sportPurchase => (
                      <tr key={`sportPurchase-${sportPurchase.id}`}>
                        <td><span className="has-text-danger">Sport</span></td>
                        <td>
                          <Link to={`/sport-purchases/${sportPurchase.id}`}>
                            {`${sportPurchase.id.slice(0, 4)}...${sportPurchase.id.slice(sportPurchase.id.length - 4)}`}
                          </Link>
                        </td>
                        <td className="has-text-right">{sportPurchase.saleQty}</td>
                        <td className="has-text-right">
                          ${new BigNumber(sportPurchase.amountUsd).dividedBy(new BigNumber(10).pow(6)).toFormat(2)}
                        </td>
                        <td className="has-text-right">
                          {new Date(sportPurchase.purchasedAtTimestamp * 1000).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' })}
                        </td>
                      </tr>
                    ))}
                    {gameCardPurchases.map(gameCardPurchase => (
                      <tr key={`gameCardPurchase-${gameCardPurchase.id}`}>
                        <td><span className="has-text-info">Game Card</span></td>
                        <td>
                          <Link to={`/game-card-purchases/${gameCardPurchase.id}`}>
                            {`${gameCardPurchase.id.slice(0, 4)}...${gameCardPurchase.id.slice(gameCardPurchase.id.length - 4)}`}
                          </Link>
                        </td>
                        <td className="has-text-right">{gameCardPurchase.saleQty}</td>
                        <td className="has-text-right">
                          ${new BigNumber(gameCardPurchase.amountUsd).dividedBy(new BigNumber(10).pow(6)).toFormat(2)}
                        </td>
                        <td className="has-text-right">
                          {new Date(gameCardPurchase.purchasedAtTimestamp * 1000).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' })}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </main>
      </>
    );
  }

  return renderResults();
}

export default ReferralsPage;
