import { useEffect, useState } from 'react';

import erc20Abi from '../config/abis/erc20.json';
import { getAddress, loadContract } from '../services/utils';
import useAppStore from '../store/use-app-store';

const worldCupAddress = getAddress('worldCup');

const useBetOnMatchContract = (match) => {
  const [
    walletConnected,
    signer
  ] = useAppStore((state) => [
    state.walletState.walletConnected,
    state.walletState.signer
  ])

  const [betOnMatchState, setBetOnMatchState] = useState({
    loading: true,
    balance: '0',
    allowance: false
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!walletConnected || !match.stakedToken) {
        setBetOnMatchState({
          loading: false,
          balance: '0',
          allowance: false
        });  
      }

      const erc20Contract = loadContract(match.stakedToken, erc20Abi);
      const balance = await erc20Contract.balanceOf(walletConnected);
      const allowance = await erc20Contract.allowance(walletConnected, worldCupAddress);

      setBetOnMatchState({
        loading: false,
        balance: balance.toString(),
        allowance: allowance.gt(0)
      });
    }

    const interval = setInterval(fetchData, 2_000);
    fetchData();

    return () => {
      clearInterval(interval);
    }
  }, [walletConnected, match.stakedToken]);

  return {
    walletConnected,
    signer,
    betOnMatchState
  }
}

export default useBetOnMatchContract;
