import asyncFetchPlayers from '../blockchain/async-fetch-players';
import playersInitialState from '../states/players-initial-state';

const createPlayersSlice = (set, get) => ({
  playersState: playersInitialState,
  loadingPlayers: true,
  updatingPlayers: false,
  fetchPlayers: async (connectedWallet) => {
    if (!get().updatingPlayers) {
      set((state) => ({ ...state, updatingPlayers: true }));
      const rs = await asyncFetchPlayers([...get().playersState.players], connectedWallet);
      set(() => ({playersState: rs, updatingPlayers: false}));
      if (get().loadingPlayers) {
        set(() => ({loadingPlayers: false}));
      }
    }
  },
});

export default createPlayersSlice;
