import React, { useEffect, useState } from 'react';
import WorldCupNav from '../../shared/components/world-cup-nav';
import { classNames } from '../../services/utils';

import Loading from '../../shared/components/loading';

import stagesJson from '../../db/stages.json';

import fixturesJson from '../../db/fixtures.json';

const teamNames = ['-', 'Argentina','Belgium','Brazil','Cameroon','Canada','Croatia','Denmark','Ecuador','England','France','Germany','Ghana','Iran','Japan','Mexico','Morocco','Netherlands','Poland','Portugal','Qatar','Saudi Arabia','Senegal','Serbia','South Korea','Spain','Switzerland','Tunisia','Uruguay','USA','Costa Rica','Australia','Wales'];
const matchOrder = [
  1670079600, 1670094000, 1670252400, 1670266800, 1670166000, 1670180400, 1670338800, 1670353200,
  1670612400, 1670598000, 1670698800, 1670684400,
  1670958000, 1671044400,
  1671289200,
  1671375600
];

const sortFixtures = (a, b) => {
  if (matchOrder.indexOf(a.startTime) < matchOrder.indexOf(b.startTime)) {
    return -1;
  }

  if (matchOrder.indexOf(a.startTime) > matchOrder.indexOf(b.startTime)) {
    return 1;
  }

  return 0;
}

const WorldCupBracketPage = () => {
  const [loading, setLoading] = useState(true);
  const [fixtures, setFixtures] = useState([]);
  const [col, setCol] = useState(0);

  useEffect(() => {
    const fetchBracket = async () => {
      setFixtures(fixturesJson);
      setLoading(false);
    }
    fetchBracket();
  }, [setFixtures, setLoading]);

  if (loading) {
    return <Loading />;
  }

  const filtered = (fixtures, stageId) => {
    return fixtures
      .filter((fixture) => fixture.stageId === stageId)
      .sort(sortFixtures);
  }

  return (
    <>
      <div
        style={{
          backgroundImage: 'url(/images/hero.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          zIndex: '-2'
        }}
      />
      <WorldCupNav tab="bracket">
        <h1 className="title has-text-light">World Cup - Playoff Bracket</h1>
      </WorldCupNav>
      <main role="main" className="section is-clipped">
        <div className="container">
          <div className="columns is-desktop is-vcentered is-hidden-touch">
            {stagesJson.data.map((stage, i) => (
              <div key={`bracket-col-title-${i}`} className="column is-one-fifth">
                <h1 className="title has-text-light has-text-centered">{stage.name}</h1>
              </div>
            ))}
          </div>
          <div className="is-hidden-desktop is-flex mb-5">
            <div className="select is-rounded mx-auto">
              <select onChange={(evt) => setCol(Number(evt.target.value))}>
                {stagesJson.data.map((stage, i) => (
                  <option key={`bracket-option-${i}`} value={i}>
                    {stage.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="columns is-desktop">
            {stagesJson.data.map((stage, i) => (
              <div
                key={`bracket-col-${i}`}
                className={classNames('column', 'is-one-fifth-desktop', `bracket-col-${i}`, col !== i && 'is-hidden-touch')}
              >
                {filtered(fixtures, stage.id).map((fixture, j) => (
                  <div key={`bracket-match-${j}`} className="is-flex is-flex-direction-column is-justify-content-center bracket-match">
                    <div className={classNames('tag', 'is-medium', 'pl-1', 'is-rounded', 'mb-1', 'is-light', 'is-justify-content-space-between', fixture.localTeamId === fixture.winnerTeamId ? 'is-success' : 'is-info')}>
                      {fixture.localTeamId === 0 ? (
                        <span className="has-text-primary ml-2">TBD</span>
                      ) : (
                        <>
                          <figure className="image is-24x24 mr-1">
                            <img className="is-rounded" src={`/images/flags/${teamNames[fixture.localTeamId]}.png`} style={{ height: '100%', objectFit: 'cover', border: '1px solid #ddd' }} alt={teamNames[fixture.localTeamId]} />
                          </figure>
                          <span>{teamNames[fixture.localTeamId]}</span>
                          <span className="ml-auto has-text-weight-bold">
                            {fixture.localTeamScore}
                            {fixture.localTeamPenScore !== null ? (
                              <small>({fixture.localTeamPenScore})</small>
                            ) : null}
                          </span>
                        </>
                      )}
                    </div>
                    <div className={classNames('tag', 'is-medium', 'pl-1', 'is-rounded', 'mb-1', 'is-light', 'is-justify-content-space-between', fixture.localTeamId === fixture.winnerTeamId ? 'is-success' : 'is-info')}>
                      {fixture.visitorTeamId === 0 ? (
                        <span className="has-text-primary ml-2">TBD</span>
                      ) : (
                        <>
                          <figure className="image is-24x24 mr-1">
                            <img className="is-rounded" src={`/images/flags/${teamNames[fixture.visitorTeamId]}.png`} style={{ height: '100%', objectFit: 'cover', border: '1px solid #ddd' }} alt={teamNames[fixture.visitorTeamId]} />
                          </figure>
                          <span>{teamNames[fixture.visitorTeamId]}</span>
                          <span className="ml-auto has-text-weight-bold">
                            {fixture.visitorTeamScore}
                            {fixture.visitorTeamPenScore !== null ? (
                              <small>({fixture.visitorTeamPenScore})</small>
                            ) : null}
                          </span>
                        </>
                      )}
                    </div>
                    <div className="tag is-primary mx-5">
                      {new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(fixture.startTime * 1_000)}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </main>
    </>
  );
}

export default WorldCupBracketPage;
