import React, { useContext, useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate, faDiamond, faFutbol } from '@fortawesome/free-solid-svg-icons';
import Countdown from 'react-countdown';

import useSportSaleContract from '../../hooks/use-sport-sale-contract';
import { classNames } from '../../services/utils';
import SportSaleModal from './components/sport-sale-modal';
import { WalletContext } from '../../context/wallet';
import useAppStore from '../../store/use-app-store';

const renderer = ({ days, hours, minutes }) => {
  return (
    <span className="is-flex icon-text is-justify-content-center is-align-items-center">
      <span className="has-text-centered">
        <strong className="is-size-3 has-text-white is-family-secondary">{days}</strong><br />
        <small className="has-text-white">DAYS</small>
      </span>
      <span className="icon mx-5">
        <FontAwesomeIcon icon={faDiamond} size="1x" className="has-text-success" />
      </span>
      <span className="has-text-centered">
        <strong className="is-size-3 has-text-white is-family-secondary">{hours}</strong><br />
        <small className="has-text-white">HOURS</small>
      </span>
      <span className="icon mx-5">
        <FontAwesomeIcon icon={faDiamond} size="1x" className="has-text-success" />
      </span>
      <span className="has-text-centered">
        <strong className="is-size-3 has-text-white is-family-secondary">{minutes}</strong><br />
        <small className="has-text-white">MIMUTES</small>
      </span>
    </span>
  );
};

const SportSalePage = () => {
  const {
    openConnectModal,
  } = useContext(WalletContext);

  const walletConnected = useAppStore((state) => state.walletState.walletConnected);


  const { sportSaleState, setReload } = useSportSaleContract();
  const [selectedPack, setSelectedPack] = useState(null);
  const [isModalActive, setIsModalActive] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setReload(prevState => prevState + 1);
    }, 5000);

    return () => clearInterval(interval);
  }, [setReload]);

  const handleCloseModal = () => {
    setSelectedPack(null);
    setIsModalActive(false);
  }

  const handleSelectPack = (pack) => {
    setSelectedPack(pack);
    setIsModalActive(true);
  }

  const handleConnect = () => {
    if (!walletConnected) {
      openConnectModal();
    }
  }

  const renderConnectOrBuyButton = (pack, index) => {
    if (walletConnected) {
      return (
        <button
          type="button"
          className={classNames('button', 'is-fullwidth', 'is-large', 'is-rounded', index === 1 ? 'is-primary' : 'is-light')}
          onClick={() => handleSelectPack(pack)}
          disabled={!sportSaleState.started || new BigNumber(sportSaleState.nftRemaining).eq(0)}
        >
          Buy!
        </button>
      );
    }

    return (
      <button
        type="button"
        className={classNames('button', 'is-fullwidth', 'is-large', 'is-rounded', index === 1 ? 'is-primary' : 'is-light')}
        onClick={handleConnect}
        aria-haspopup="true"
        aria-controls={`dropdown-connect-${index}`}
      >
        Connect
      </button>
    );
  }

  const currentDiscount = useMemo(() => {
    for (let i = 0; i < sportSaleState.discounts.length; i++) {
      if (new BigNumber(sportSaleState.nftRemaining).gt(sportSaleState.discounts[i].qty)) {
        return sportSaleState.discounts[i];
      }
    }

    return null;
  }, [sportSaleState.discounts, sportSaleState.nftRemaining]);

  const discountPer = (discount) => {
    return discount && new BigNumber(discount.per).dividedBy(100).toFormat();
  }

  const discountDetails = (discount) => {
    let base = new BigNumber(sportSaleState.nftRemaining);
    if (discount.id > 0 && new BigNumber(sportSaleState.discounts[discount.id - 1].qty).lt(base)) {
      base = new BigNumber(sportSaleState.discounts[discount.id - 1].qty);
    }

    if (base.gt(discount.qty)) {
      return (
        <p className="is-6">
          {base.minus(discount.qty).toFormat()} NFTs remaining
        </p>
      );
    }
  
    return (
      <p className="is-6 has-text-danger">SOLDOUT</p>
    );
  }

  const renderPackDiscount = (i) => {
    const sum = new BigNumber(Number(currentDiscount?.per || 0) + Number(i > 0 ? (i * 1000) : 0)).dividedBy(100);
    const arr = [];
    if (currentDiscount) {
      arr.push(new BigNumber(currentDiscount.per).dividedBy(100).toFormat());
    }
    if (i > 0) {
      arr.push(i * 10);
    }

    return (
      <span className="is-size-6">
        {sum.toFormat()}%OFF
      </span>
    );
  }

  const renderPackDesc = (pack) => {
    switch(pack.id) {
      case 0:
        return 'Best to strategy';
      case 1:
        return 'Best flexibility';
      case 2:
        return 'Best value';
      default:
        return '';
    }
  }

  const renderLoadingOrPage = () => {
    if (sportSaleState.loading) {
      return (
        <div className="pageloader is-active" style={{ backgroundImage: 'url(/images/hero.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
          <span className="title">Loading</span>
        </div>
      );
    }

    const nftRemaining = new BigNumber(sportSaleState.nftRemaining);

    return (
      <>
        <header className="hero is-small is-black" style={{ backgroundImage: 'url(/images/banner.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
          <div className="hero-body">
            <div className="container has-text-centered">
              <div className="columns is-desktop is-vcentered">
                <div className="column is-8 is-offset-2">
                  <h1 className="title is-family-secondary">Earn Bigger Prizes With Sport NFTs</h1>
                  <h2 className="subtitle is-family-secondary">Train your Players, get bigger rewards and collect this items</h2>
                  {sportSaleState.started && currentDiscount ? (
                    <h1 className="title is-family-secondary has-text-warning mb-0">Open Early Access {currentDiscount.id + 1}: {new BigNumber(currentDiscount.per).dividedBy(100).toFormat()}% off</h1>
                  ) : null}
                  {sportSaleState.started && new BigNumber(sportSaleState.nftRemaining).gt(0) && !currentDiscount ? (
                    <h1 className="title is-family-secondary has-text-warning mb-0">Open Public Access</h1>
                  ) : null}
                  {!sportSaleState.started ? (
                    <div className="columns is-justify-content-center is-align-items-center">
                      <div className="column is-narrow">
                        <h3 className="is-size-4 has-text-white has-text-weight-bold has-text-centered is-family-secondary">
                          Countdown to NFT Sale 
                        </h3>
                      </div>
                      <div className="column is-narrow is-hidden-mobile">
                        <span className="icon is-large">
                          <FontAwesomeIcon icon={faFutbol} size="2x" className="has-text-success" />
                        </span>
                      </div>
                      <div className="column is-narrow">
                        <Countdown date={new BigNumber(sportSaleState.startTime).times(1000).toNumber()} renderer={renderer} />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="column is-2-desktop">
                  <div className="is-flex is-justify-content-center">
                    <div className="is-relative" style={{ lineHeight: '1' }}>
                      <FontAwesomeIcon icon={faCertificate} size="8x" className="has-text-success" />
                      <div
                        style={{
                          position: 'absolute',
                          top: '0',
                          left: '0',
                          width: '100%',
                          height: '100%'
                        }}
                        className="is-flex is-align-items-center is-justify-content-center is-flex-direction-column"
                      >
                        {nftRemaining.gt(0) ? (
                          <>
                            <span style={{ textShadow: '0px 0px 6px rgba(255,255,255,0.5)' }} className="has-text-light is-size-3">{nftRemaining.toFormat()}</span>
                            <span style={{ textShadow: '0px 0px 6px rgba(255,255,255,0.5)' }} className="has-text-light is-size-6">NFTs left</span>
                          </>
                        ) : (
                          <span className="has-text-light is-size-3 neon-text">SOLDOUT</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <main role="main">
          <div className="section pb-0 has-background-primary" style={{ backgroundImage: 'url(/images/hero.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <div className="container">
              <div className="columns is-desktop is-justify-content-center is-align-items-end is-variable is-0-desktop">
                {sportSaleState.packs.map((pack, i) => (
                  <div key={`pack-${pack.id}`} className="column is-one-quarter-desktop">
                    <div className={`pricing-card pricing-card-${i} is-relative`}>
                      {i > 0 || currentDiscount ? (
                        <span
                          className="tag is-success is-large is-rounded"
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                          }}
                        >
                          {renderPackDiscount(i)}
                        </span>
                      ) : null}
                      <div className="columns is-multiline">
                        <div className="column is-one-third is-full-desktop">
                          <figure className="image is-3by4">
                            <img src={`/images/sport-boxes/box-${new BigNumber(pack.qty).toNumber()}.png`} alt={`Box ${new BigNumber(pack.qty).toNumber()}`} />
                          </figure>
                        </div>
                        <div className="column is-two-thirds is-full-desktop is-flex is-flex-direction-column">
                          <div className={classNames('is-flex', 'is-flex-direction-column', i === 1 ? 'has-text-dark' : 'has-text-light')}>
                            <div className="is-flex is-justify-content-center is-align-items-center">
                              <h3 style={{ lineHeight: '1' }}>
                                {currentDiscount ? (<span className="is-line-through ">${pack.price}</span>) : null}
                                <span className={classNames('ml-1', 'is-size-2', 'has-text-weight-bold', i === 1 ? 'has-text-primary' : 'has-text-success')}>
                                  ${new BigNumber(pack.price).minus(currentDiscount ? new BigNumber(pack.price).times(currentDiscount.per).dividedBy(10000) : 0).toFormat(2)}
                                </span>
                              </h3>
                              {/* <h3 className={classNames('is-size-2', 'mr-1', i === 1 ? 'has-text-primary' : 'has-text-success')} style={{ lineHeight: '1' }}>
                                ${new BigNumber(pack.price).dividedBy(pack.qty).toFormat()}
                              </h3>
                              <small>per player</small> */}
                            </div>
                            <p className="mx-auto">Get {pack.qty} random Sport NFT{new BigNumber(pack.qty).gt(1) ? 's' : ''}</p>
                            <p className="mx-auto mb-5">"{renderPackDesc(pack)}"</p>
                            {/* <p className="mx-auto mb-5">
                              {currentDiscount ? (<span className="is-line-through">${pack.price}</span>) : null}
                              <span className={classNames('mr-1', 'is-size-5', 'has-text-weight-bold', i === 1 ? 'has-text-primary' : 'has-text-success')}>
                                ${new BigNumber(pack.price).minus(currentDiscount ? new BigNumber(pack.price).times(currentDiscount.per).dividedBy(10000) : 0).toFormat(2)}
                              </span> per Lootbox
                            </p> */}
                            <div className="mt-auto">
                              {renderConnectOrBuyButton(pack, i)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <section className="section is-medium">
            <div className="columns is-centered mb-6 is-multiline">
              {sportSaleState.discounts.map((discount, i) => (
                <div key={`discount-${i}`} className="column is-narrow has-text-centered">
                  <div className={classNames('message', (currentDiscount?.id === discount.id) && 'is-success')}>
                    <div className={classNames('message-body', 'px-6', (currentDiscount?.id === discount.id) && 'has-text-primary')}>
                      <p className="heading">Early access {i + 1}</p>
                      <p className="is-size-2">
                        {discountPer(discount)}
                        <small className="is-size-5">% OFF</small>
                      </p>
                      {discountDetails(discount)}
                    </div>
                  </div>
                </div>
              ))}
              <div key="discount-4" className="column is-narrow has-text-centered">
                <div className={classNames('message', !currentDiscount && 'is-success')}>
                  <div className={classNames('message-body', 'px-6', !currentDiscount && 'has-text-primary')}>
                    <p className="heading">Public access</p>
                    <p className="is-size-2">
                      0
                      <small className="is-size-5">% OFF</small>
                    </p>
                    {discountDetails({ id: 3, qty: 0 })}
                  </div>
                </div>
              </div>
            </div>
            <div className="columns is-centered">
              <div className="column is-narrow">
                <div className="message is-dark">
                  <div className="message-body content">
                    <h3>Hurry up and enjoy VIP early Access!</h3>
                    <ul style={{ listStyleImage: 'url(/images/logo-small.png)' }}>
                      <li>Discount on top on final sale per Lootbox.</li>
                      <li>This VIP perk shows up on purchase.</li>
                      <li>First-Come First-Serve priority on discounts.</li>
                      <li>Buy on site, and Trade on Opensea.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {selectedPack ? (
          <SportSaleModal
            isModalActive={isModalActive}
            selectedPack={selectedPack}
            currentDiscount={currentDiscount}
            handleCloseModal={handleCloseModal}
          />
        ) : null}
      </>
    );
  }

  return renderLoadingOrPage();
}

export default SportSalePage;
