import { getAddress, multiCall } from '../services/utils';
import sportNftAbi from '../config/abis/sport-nft.json';

const asyncFetchSportsData = async (prevSports) => {
  let sports = [];

  const sportNftAddress = getAddress('sportNft');

  const attrsCalls = [];
  for (let i = 0; i < prevSports.length; i++) {
    attrsCalls.push({
      name: 'getAttrs',
      address: sportNftAddress,
      params: [prevSports[i].id]
    });
  }
  const attrsRs = await multiCall(sportNftAbi, attrsCalls);

  for (let i = 0; i < prevSports.length; i++) {
    let sportMetaData = {};
    if (prevSports[i].name === undefined) {
      let response = await fetch(`${process.env.REACT_APP_META_API_URL}/sports/${prevSports[i].id}`);
      sportMetaData = await response.json();
    }

    sports.push(Object.assign({...prevSports[i]}, {
      power: attrsRs[i][0].toNumber(),
      rarity: attrsRs[i][1].toNumber(),
      used: attrsRs[i][2].toNumber(),
      ...sportMetaData
    }));
  }

  return {sports};
}

export default asyncFetchSportsData;
