const defaultSelectedProvider = () => {
  const value = localStorage.getItem('selectedProvider') || '';
  if (value === 'METAMASK') {
    return value;
  }

  if (value === 'WALLET_CONNECT') {
    return value;
  }

  return undefined;
}

const walletInitialState = {
  isInstalledWallet: false,
  selectedProvider: defaultSelectedProvider(),
  walletConnected: undefined,
  signer: undefined
};

export default walletInitialState;
