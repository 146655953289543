import React, { useEffect, useState } from 'react';
import { faExternalLink, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PlayerCard from '../../shared/components/player-card';
import { getAddress } from '../../services/utils';
import Loading from '../../shared/components/loading';
import { getPowerBoost } from '../../services/players';
import useAppStore from '../../store/use-app-store';
import asyncFetchPlayersData from '../../blockchain/async-fetch-players-data';

const rarities = [
  'Rookie',
  'Semi Professional',
  'Professional',
  'World Star'
];

const PlayerPage = ({ id }) => {
  const isOwner = useAppStore((state) => state.playersState.players.some((player) => player.id === Number(id)));

  const [player, setPlayer] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPlayer = async () => {
      const { players } = await asyncFetchPlayersData([{ id }]);

      const response = await fetch(`${process.env.REACT_APP_META_API_URL}/players/${id}`);
      const playerMetaData = await response.json();

      setPlayer({...players[0], ...playerMetaData});
      setLoading(false);
    }

    fetchPlayer();
  }, [id, setPlayer, setLoading]);

  const getRarity = () => {
    return rarities[0];
  }

  const rarityStars = () => {
    const arr = new Array(rarities.indexOf(getRarity()) + 1);
    arr.fill('');

    return arr;
  }

  const playerAttributes = () => {
    const arr = ['Ethnicity', 'Leg Tattoos', 'Arm Tattoos', 'Angkle Boots', 'Hair'];
    if (player?.position === 1) {
      arr.push('Glove Goalkeeper');
    }

    return arr;
  }

  const getAttributeValue = (trait_type) => {
    const trait = player.attributes.find((trait) => trait.trait_type === trait_type);
    if (trait) {
      return trait.value;
    }

    return '-';
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <main role="main" className="section has-background-light">
      <div className="container">
        <div className="columns is-align-items-center">
          <div className="column is-4">
            <h1 className="title has-text-centered is-family-secondary has-text-primary">{player.name}</h1>
            <PlayerCard player={player} />
          </div>
          <div className="column is-8">
            {isOwner ? (
              <div className="buttons is-justify-content-end">
                <a href={`https://opensea.io/assets/matic/${getAddress('nftPlayer')}/${player.id}`} target="_blank" rel="noreferrer" className="button is-primary is-rounded is-outlined">
                  <span className="icon-text">
                    <span>TRADE</span>
                    <span className="icon">
                      <FontAwesomeIcon icon={faExternalLink} />
                    </span>
                  </span>
                </a>
              </div>
            ) : null}
            <p className="mb-5">{player.description}</p>
            <div>
              <div className="divider">Rarity</div>
            </div>
            <div className="has-text-centered">
              <div>
                {rarityStars().map((star, i) => (
                  <span key={`star-b-#${i}`} className="icon">
                    <FontAwesomeIcon icon={faStar} className="has-text-success" />
                  </span>
                ))}
              </div>
              <p className="title has-text-primary">{getRarity()}</p>
            </div>
            <div>
              <div className="divider">Power Boost</div>
            </div>
            <div className="has-text-centered">
              <p className="title has-text-success">{getPowerBoost(player)}%</p>
            </div>
            <div>
              <div className="divider">Skills</div>
            </div>
            <nav className="level mb-5">
              {['attack', 'defense', 'physical', 'morale', 'experience'].map(el => (
                <div key={`skill-large-${el}`} className="level-item has-text-centered">
                  <div>
                    <p className="heading">{el}</p>
                    <p className="title has-text-primary">{player[el]}</p>
                  </div>
                </div>
              ))}
            </nav>
            <div>
              <div className="divider">Attributes</div>
            </div>
            <nav className="level mb-5">
              {playerAttributes().map(el => (
                <div key={`att-large-${el}`} className="level-item has-text-centered">
                  <div>
                    <p className="heading">{el}</p>
                    <p className="subtitle">{getAttributeValue(el)}</p>
                  </div>
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </main>
  );
}

export default PlayerPage;
