import React from 'react';
import { Link } from 'wouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import PlayerCard from '../../../../shared/components/player-card';
import useAppStore from '../../../../store/use-app-store';

const ProfilePlayers = () => {
  const [
    loadingPlayers,
    players
  ] = useAppStore((state) => [
    state.loadingPlayers,
    state.playersState.players
  ]);

  return (
    <div className="columns is-centered is-multiline">
      {loadingPlayers ? (
        <div className="column is-narrow">
          <span className="icon is-large">
            <FontAwesomeIcon icon={faCog} spin size="4x" />
          </span>
        </div>
      ) : (
        players.length === 0 ? (
          <div className="column is-half">
            <article className="message is-warning">
              <div className="message-body">
                Your bucket is empty. <Link to="/sale">Buy here</Link> and became a superstar!
              </div>
            </article>
          </div>
        ) : (
          players.map(player => (
            <div key={`players-${player.id}`} className="column is-one-quarter">
              <h3 className="subtitle has-text-centered">Player #{player.id}</h3>
              <PlayerCard player={player} navigate />
            </div>
          ))
        )
      )}
    </div>
  );
}

export default ProfilePlayers;
