import BigNumber from 'bignumber.js';
import React from 'react';
import { classNames, numberWithDecimals } from '../../../../../../services/utils';

const Tvl = ({ match, width = 'is-6-tablet is-3-desktop' }) => {
  const per = (value) => {
    if (new BigNumber(match.totalAmount).gt(0)) {
      return new BigNumber(value).times(100).dividedBy(match.totalAmount).toFormat(2);
    }

    return '0.00';
  }

  const perClass = (value, betOption) => {
    const arr = [];
    if (new BigNumber(value).gt(0)) {
      arr.push('has-text-weight-bold');
    }
    if (match.bet !== 0) {
      arr.push(match.bet === betOption ? 'has-background-success-light' : 'has-background-danger-light');
    }

    return arr;
  }

  return (
    <div className={classNames('column', width)}>
      <div className="is-flex is-align-items-center" style={{ height: '48px' }}>
        <h3 className="is-family-secondary has-text-primary mb-0 mr-auto">
          Jackpot ({match.tokenSymbol})
        </h3>
      </div>
      <div className="is-flex is-justify-content-space-between has-background-light p-1">
        <small>Total:</small>
        <span className={classNames(new BigNumber(match.totalAmount).gt(0) && 'has-text-weight-bold')}>
          {numberWithDecimals(match.totalAmount, match.tokenDecimals).toFormat(2)}
        </span>
      </div>
      <div className="is-flex is-justify-content-space-between p-1">
        <small>Win {match.localTeamShortName}:</small>
        <span className={classNames(...perClass(match.localWinAmount, 1))}>
          {per(match.localWinAmount)}%
        </span>
      </div>
      <div className="is-flex is-justify-content-space-between has-background-light p-1">
        <small>Win {match.visitorTeamShortName}:</small>
        <span className={classNames(...perClass(match.visitorWinAmount, 2))}>
          {per(match.visitorWinAmount)}%
        </span>
      </div>
      {match.matchType === 0 ? (
        <div className="is-flex is-justify-content-space-between p-1">
          <small>Tie:</small>
          <span className={classNames(...perClass(match.tieAmount, 3))}>
            {per(match.tieAmount)}%
          </span>
        </div>
      ) : null}
    </div>
  );
}

export default Tvl;
