import React, { useContext, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import confetti from 'canvas-confetti';

import { classNames, getAddress, loadContract, numberWithDecimals } from '../../../../../../services/utils';

import worldCupAbi from '../../../../../../config/abis/world-cup.json';

import { WalletContext } from '../../../../../../context/wallet';
import { ToastContext } from '../../../../../../context/toast';
import { trainingErrorMessage } from '../../../../../training/utils';
import useAppStore from '../../../../../../store/use-app-store';

const worldCupAddress = getAddress('worldCup');

const UserClaim = ({ match }) => {
  const { openConnectModal } = useContext(WalletContext);
  const { addToast } = useContext(ToastContext);

  const [
    walletConnected,
    signer,
    setUserClaimedAmount
  ] = useAppStore((state) => [
    state.walletState.walletConnected,
    state.walletState.signer,
    state.setUserClaimedAmount
  ]);

  const handleConnect = () => {
    if (!walletConnected) {
      openConnectModal();
    }
  }

  const [isPending, setIsPending] = useState(false);

  const handleClaim = async () => {
    if (!isClaimable) {
      return;
    }

    setIsPending(true);
    let tx;
    try {
      const usdcContract = loadContract(worldCupAddress, worldCupAbi, signer);
      tx = await usdcContract.claim(match.pid);
      await tx.wait();
      setUserClaimedAmount(match.pid);
      addToast('You claimed your reward successfully. Please check your wallet.', 'is-success');
      confetti({
        resize: true,
        particleCount: 200,
        startVelocity: 30,
        gravity: 0.5,
        spread: 350,
        origin: {
          x: 0.5,
          y: 0.3,
        },
      });
  } catch (e) {
      console.log(trainingErrorMessage(e));
      tx = { error: e.data?.message || e.message };
    }

    if(tx.error !== undefined) {
      console.log('error', tx.error);
      addToast('Claim was unable to fulfill. Please try again.', 'is-danger');
    }

    setIsPending(false);
  }

  const userClaimedAmount = useMemo(() => {
    return numberWithDecimals(match.userClaimedAmount, match.tokenDecimals);
  }, [match.userClaimedAmount, match.tokenDecimals]);

  const renderAmount = () => {
    if (userClaimedAmount.gt(0)) {
      return userClaimedAmount;
    }

    return numberWithDecimals(match.userPendingReward, match.tokenDecimals);
  }

  const renderText = () => {
    if (userClaimedAmount.gt(0)) {
      return 'Claimed';
    }

    return 'Claim!';
  }

  const renderConnectOrClaimButton = () => {
    if (!walletConnected) {
      return (
        <button
          onClick={handleConnect}
          className="button is-primary is-outlined is-rounded is-fullwidth"
        >
          Connect
        </button>
      );
    }

    return (
      <button
        onClick={handleClaim}
        disabled={isPending || !isClaimable}
        className={classNames('button', 'is-primary', 'is-outlined', 'is-rounded', 'is-fullwidth', isPending && 'is-loading')}
      >
        {renderText()}
      </button>
    );
  }

  const isClaimable = useMemo(() => {
    return new BigNumber(match.userPendingReward).gt(0);
  }, [match.userPendingReward]);

  const boxClass = () => {
    if (isClaimable) {
      return 'has-background-primary has-text-light';
    }

    return 'has-background-light';
  }

  return (
    <div className="column is-6-tablet is-2-desktop is-flex">
      <div className="is-flex is-flex-grow-1 is-flex-direction-column is-align-items-center is-justify-content-space-between">
        <div className="is-flex is-align-items-center" style={{ height: '48px' }}>
          <strong  className="is-family-secondary has-text-primary">
            Your reward
          </strong>
        </div>
        <p className="subtitle">
          <span className={classNames('py-1', 'px-2', boxClass())} style={{ borderRadius: '6px' }}>
            <span>{renderAmount().toFormat(2)}</span> <small className="is-size-7">{match.tokenSymbol}</small>
          </span>
        </p>
        {renderConnectOrClaimButton()}
        <p>
          <small className="is-italic">* Reward Fee: {match.treasuryFee / 100}%</small>
        </p>
      </div>
    </div>
  );
}

export default UserClaim;
