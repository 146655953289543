import React from 'react';
import { Link } from 'wouter';
import { classNames } from '../../../services/utils';

const WorldCupNav = ({ children, tab }) => {
  return (
    <header className="hero is-dark"  style={{ backgroundImage: 'url(/images/banner.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      <div className="hero-body has-text-centered">
        <div className="container">
          {children}
        </div>
      </div>
      <div className="hero-foot">
        <nav className="tabs is-boxed is-centered">
          <div className="container">
            <ul>
              <li className={classNames(tab === 'game' && 'is-active')}>
                <Link to="/world-cup-game">Game</Link>
              </li>
              <li className={classNames(tab === 'standings' && 'is-active')}>
                <Link to="/world-cup-standings">Standings</Link>
              </li>
              <li className={classNames(tab === 'bracket' && 'is-active')}>
                <Link to="/world-cup-bracket">Bracket</Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default WorldCupNav;
