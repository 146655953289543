import React from 'react';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { classNames } from '../../../services/utils';
import { getPowerBoost } from '../../../services/players';
import useLocation from '../../../hooks/use-location';

const positions = ['-', 'GK', 'DF', 'MC', 'FW'];

const rarities = [
  'Rookie',
  'Semi Professional',
  'Professional',
  'World Star'
];

const countries = ['-', 'Argentina','Belgium','Brazil','Cameroon','Canada','Croatia','Denmark','Ecuador','England','France','Germany','Ghana','Iran','Japan','Mexico','Morocco','Netherlands','Poland','Portugal','Qatar','Saudi Arabia','Senegal','Serbia','South Korea','Spain','Switzerland','Tunisia','Uruguay','US','Costa Rica','Australia','Wales'];

const PlayerCard = ({ player, navigate = false }) => {
  const [, setLocation, ] = useLocation();

  const getBirth = () => {
    const birthday = new Date(player.birth * 1000);

    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);

    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  const getRarity = () => {
    return rarities[0];
  }

  const rarityStars = () => {
    const arr = new Array(rarities.indexOf(getRarity()) + 1);
    arr.fill('');

    return arr;
  }

  const country = () => {
    return typeof player.country === 'number' ? countries[player.country] : player.country;
  }

  return (
    <div
      className={classNames('player-card', 'is-relative', navigate && 'is-clickable')}
      style={{ boxShadow: '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)', overflow: 'hidden' }}
      onClick={() => navigate && setLocation(`/players/${player.id}`)}
    >
      <figure className="image is-3by4">
        <img src={`https://degoverse.ams3.cdn.digitaloceanspaces.com/players/${player.id}.png`} alt={player.name} />
      </figure>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'transparent',
          borderWidth: '1.5rem 2.5rem 1.5rem 1rem',
          borderColor: '#f5f5f5',
          borderStyle: 'solid',
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: '1rem',
          width: '3rem',
          bottom: 0,
          boxShadow: '0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12), 0 7px 8px -4px rgba(0,0,0,0.20)',
        }}
        className="has-background-primary is-flex is-flex-direction-column is-align-items-center"
      >
        <figure
          className="image is-16x9 my-5"
          style={{ boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)' }}
        >
          <img src={`/images/flags/${country()}.png`} alt={country()} />
        </figure>

        {rarityStars().map((_, i) => (
          <span key={`star-a-${i}`} className="icon is-medium">
            <FontAwesomeIcon icon={faStar} className="has-text-success" size="lg" />
          </span>
        ))}
        <strong className="mt-auto has-text-light has-text-centered mb-3">
          Age {getBirth()}
        </strong>
        <div
          className="has-background-success mb-5 is-flex is-align-items-center is-justify-content-center"
          style={{
            width: '3rem',
            height: '3rem',
          }}
        >
          <strong className="has-text-primary">
            {positions[player.position]}
          </strong>
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          top: '1.5rem',
          right: '2.5rem',
          width: '0.25rem',
          bottom: '1.5rem',
          boxShadow: '0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12), 0 7px 8px -4px rgba(0,0,0,0.20)',
        }}
        className="has-background-light is-flex is-flex-direction-column is-align-items-center is-justify-content-end"
      >
        {['defense', 'attack', 'physical', 'morale', 'experience'].map((el, i) => (
          <div
            key={`box-skill-${el}`}
            style={{
              width: '2.5rem',
              height: '2.5rem',
              transform: 'rotate(45deg)',
              borderRadius: '4px',
            }}
            className={`${i < 3 ? 'has-background-primary' : 'has-background-success'} is-flex is-align-items-center is-justify-content-center mb-4`}
          >
            <strong className="has-text-light" style={{ transform: 'rotate(-45deg)' }}>
              {player[el]}
            </strong>
          </div>
        ))}
        <div
          style={{
            width: '3.5rem',
            height: '3.5rem',
            borderRadius: '4px',
          }}
          className="mt-1 has-background-warning is-flex is-align-items-center is-justify-content-center"
        >
          <strong className="has-text-primary has-text-light">
            {getPowerBoost(player)}%
          </strong>
        </div>
      </div>
    </div>
  );
}

export default PlayerCard;