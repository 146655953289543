import { useEffect, useState } from 'react';

import erc20Abi from '../config/abis/erc20.json';

import { getAddress, multiCall } from '../services/utils';
import BigNumber from 'bignumber.js';
import useAppStore from '../store/use-app-store';

const useTrainingContract = (trainingCenterId) => {
  const [
    walletConnected,
    signer,
    players,
    sportNfts,
    gameCards,
    trainingCenters,
    trainingSportItems,
    trainingGameCardItems
  ] = useAppStore((state) => [
    state.walletState.walletConnected,
    state.walletState.signer,
    state.playersState.players,
    state.sportsState.sports,
    state.gameCardsState.gameCards,
    state.trainingState.trainingCenters,
    state.trainingState.sportItems,
    state.trainingState.gameCardItems
  ]);

  const [allowance, setAllowance] = useState(new BigNumber(0));
  const [balance, setBalance] = useState(new BigNumber(0));

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTraining = async () => {
      if (!walletConnected) {
        setAllowance(new BigNumber(0));
        setBalance(new BigNumber(0));
        return;
      }

      const [
        userAllowance,
        userBalance
      ] = await multiCall(erc20Abi, [
        {
          name: 'allowance',
          address: getAddress('usdc'),
          params: [walletConnected, getAddress('training')]
        },
        {
          name: 'balanceOf',
          address: getAddress('usdc'),
          params: [walletConnected]
        }
      ]);
      setAllowance(new BigNumber(userAllowance));
      setBalance(new BigNumber(userBalance));
      setLoading(false);
    }

    const interval = setInterval(fetchTraining, 3_000);

    fetchTraining();

    return () => clearInterval(interval);
  }, [walletConnected, setAllowance, setBalance, setLoading]);

  return {
    walletConnected,
    signer,
    trainingCenter: trainingCenters.find((tr) => tr.id === Number(trainingCenterId)),
    players,
    sportNfts: sportNfts.filter((sportNft) => sportNft.used === 0),
    gameCards,
    allowance: new BigNumber(allowance).toJSON(),
    balance: new BigNumber(balance).toJSON(),
    trainingSportItems,
    trainingGameCardItems,
    loading
  }
}

export default useTrainingContract;
