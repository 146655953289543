import { ethers } from 'ethers';
import React, { useContext, useMemo, useState } from 'react';
import useBetOnMatchContract from '../../../../hooks/use-bet-on-match-contract';
import { classNames, getAddress, loadContract, numberWithDecimals } from '../../../../services/utils';

import { ToastContext } from '../../../../context/toast';

import erc20Abi from '../../../../config/abis/erc20.json';
import worldCupAbi from '../../../../config/abis/world-cup.json';
import BigNumber from 'bignumber.js';
import { trainingErrorMessage } from '../../../training/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';

const worldCupAddress = getAddress('worldCup');

const BetOnMatchModal = ({ match, handleModalClose }) => {
  const { addToast } = useContext(ToastContext);

  const {
    walletConnected,
    signer,
    betOnMatchState
  } = useBetOnMatchContract(match);

  const [bet, setBet] = useState('-1');
  const [amount, setAmount] = useState('');
  const [isPending, setIsPending] = useState(false);

  const handleAmountChange = (evt) => {
    setAmount(evt.target.value);
  }

  const handleSetAmount = (percentage) => {
    const val = numberWithDecimals(betOnMatchState.balance * percentage, match.tokenDecimals)
      .times(new BigNumber(10).pow(match.tokenDecimals))
      .toFixed(0);
    setAmount(numberWithDecimals(val, match.tokenDecimals).toString());
  }

  const handleApprove = async () => {
    setIsPending(true);
    let tx;
    try {
      const erc20Contract = loadContract(match.stakedToken, erc20Abi, signer);
      tx = await erc20Contract.approve(worldCupAddress, ethers.constants.MaxUint256);
      await tx.wait();
      addToast('World Cup Game Contract approved successfully!', 'is-success');
    } catch (e) {
      tx = { error: e.data?.message || e.message };
    }

    if(tx.error !== undefined) {
      console.log('error', tx.error);
      addToast('Approve Failed.', 'is-danger');
    }

    setIsPending(false);
  }

  const handleBetOnMatch = async () => {
    if (!isValid || !walletConnected) {
      return;
    }

    setIsPending(true);
    let tx;
    try {
      const worldCupContract = loadContract(worldCupAddress, worldCupAbi, signer);
      const amt = new BigNumber(amount)
        .times(new BigNumber(10).pow(match.tokenDecimals))
        .toFixed(0);

      tx = await worldCupContract.betOnMatch(match.pid, amt, bet);
      await tx.wait();
      addToast('Bet successfully placed! Thank you.', 'is-success');
      handleModalClose();
    } catch (e) {
      console.log(trainingErrorMessage(e));
      tx = { error: e.data?.message || e.message };
    }

    if(tx.error !== undefined) {
      console.log('error', tx.error);
      addToast('Bet was unable to fulfill. Please try again.', 'is-danger');
    }

    setIsPending(false);
  }

  const isValid = useMemo(() => {
    if (match.finished || match.playingNow || match.paused) {
      return false;
    }

    const amt = new BigNumber(amount);

    return bet !== '-1' && !amt.isNaN() && amt.gt(0) && amt.lt(numberWithDecimals(betOnMatchState.balance, match.tokenDecimals));
  }, [amount, betOnMatchState.balance, bet, match.finished, match.playingNow, match.paused, match.tokenDecimals]);

  const betOptions = useMemo(() => {
    const options = [
      {
        value: '1',
        label: `Win ${match.localTeamShortName}`
      },
      {
        value: '2',
        label: `Win ${match.visitorTeamShortName}`
      },
    ];

    if (match.matchType === 0) {
      options.splice(1, 0, {
        value: '3',
        label: 'Tie'
      });
    }

    return options;
  }, [match.localTeamShortName, match.visitorTeamShortName, match.matchType]);

  const renderApproveOrBetButton = () => {
    if (!betOnMatchState.allowance) {
      return (
        <button
          onClick={handleApprove}
          disabled={isPending}
          className={classNames('button', 'is-success', (betOnMatchState.loading || isPending) && 'is-loading')}
        >
          Approve
        </button>
      );
    }

    return (
      <button
        onClick={handleBetOnMatch}
        disabled={isPending || !isValid}
        className={classNames('button', 'is-primary', (betOnMatchState.loading || isPending) && 'is-loading')}
      >
        Bet!
      </button>
    )
  }

  const potentialEarnings = () => {
    if (!['1', '2', '3'].includes(bet)) {
      return '-';
    }

    let currentAmount = new BigNumber(0);
    let currentPowerAmount = new BigNumber(0);

    let rewardBaseCalAmount = new BigNumber(0);
    let rewardAmount = new BigNumber(0);

    if (bet === '1') {
      currentAmount = currentAmount.plus(match.userLocalWinAmount);
      currentPowerAmount = new BigNumber(match.userLocalWinPowerAmount);

      rewardBaseCalAmount = new BigNumber(match.localWinAmount).plus(match.localWinPowerAmount);
    }

    if (bet === '2') {
      currentAmount = currentAmount.plus(match.userVisitorWinAmount);
      currentPowerAmount = new BigNumber(match.userVisitorWinPowerAmount);

      rewardBaseCalAmount = new BigNumber(match.visitorWinAmount).plus(match.visitorWinPowerAmount);
    }

    if (bet === '3') {
      currentAmount = currentAmount.plus(match.userTieAmount);
      currentPowerAmount = new BigNumber(match.userTiePowerAmount);

      rewardBaseCalAmount = new BigNumber(match.tieAmount).plus(match.tiePowerAmount);
    }

    let boost = new BigNumber(0);
    if (currentAmount.gt(0)) {
      boost = currentPowerAmount.times(100).dividedBy(currentAmount);
    }

    const newAmt = newAmount().times(new BigNumber(10).pow(match.tokenDecimals));

    const amt = currentAmount.plus(newAmt);
    const pow = amt.times(boost);

    if (amt.eq(0)) {
      return '0';
    }

    rewardBaseCalAmount = rewardBaseCalAmount
      .plus(newAmt)
      .minus(currentPowerAmount).plus(pow);

    if (rewardBaseCalAmount.gt(0)) {
      const totalAmount = new BigNumber(match.totalAmount).plus(newAmt);
      const treasuryAmmount = totalAmount.times(match.treasuryFee).dividedBy(10_000);
      rewardAmount = totalAmount.minus(treasuryAmmount);
    }

    const reward =  (amt.plus(pow)).times(rewardAmount).dividedBy(rewardBaseCalAmount);

    if (reward.lt(amt)) {
      return numberWithDecimals(amt, match.tokenDecimals).toFormat(2);
    }

    return numberWithDecimals(reward, match.tokenDecimals).toFormat(2);
  }

  const newAmount = () => {
    const val = new BigNumber(amount);
    if (val.isNaN()) {
      return new BigNumber(0);
    }

    return val;
  }

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={handleModalClose} />
      <div className="modal-content">
        <div className="box">
          <h3 className="is-size-4 mb-3">
            Bet On Match - <span className="is-family-secondary has-text-primary">{match.localTeamShortName} vs {match.visitorTeamShortName}</span>
          </h3>
          <p className="mb-2 has-text-centered">Choose your bet:</p>
          <div className="tabs is-toggle is-toggle-rounded is-fullwidth">
            <ul>
              {betOptions.map((bo) => (
                <li key={`tab-${bo.value}`} className={classNames(bet === bo.value && 'is-active')}>
                  <a
                    href="/"
                    onClick={(evt) => {
                      evt.preventDefault();
                      setBet(bo.value);
                    }}
                  >
                    {bo.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="field has-addons mb-3">
            <p className="control is-expanded">
              <input
                disabled={!betOnMatchState.allowance}
                value={amount}
                onInput={handleAmountChange}
                className="input has-text-right"
                type="text"
                placeholder="Amount"
              />
            </p>
            <p className="control">
              {renderApproveOrBetButton()}
            </p>
          </div>
          <div className="columns is-vcentered">
            <div className="column">
              Your {match.tokenSymbol} balance: <strong className="has-text-primary">{numberWithDecimals(betOnMatchState.balance, match.tokenDecimals).toFormat(2)}</strong>
            </div>
            <div className="column is-narrow-tablet">
              <div className="buttons">
                <button onClick={() => { handleSetAmount(0.25) }} className="button is-rounded is-small">25%</button>
                <button onClick={() => { handleSetAmount(0.5) }} className="button is-rounded is-small">50%</button>
                <button onClick={() => { handleSetAmount(0.75) }} className="button is-rounded is-small">75%</button>
                <button onClick={() => { handleSetAmount(1) }} className="button is-rounded is-small">100%</button>
              </div>
            </div>
          </div>
          <div className="divider is-size-6 mb-3">
            <FontAwesomeIcon icon={faCalculator} />
            <span className="ml-1">Calculator</span>
          </div>
          <div className="columns is-centered">
            <div className="column is-half-desktop">
              <div className="is-flex is-flex-direction-column">
                {/* <span className="tag is-large is-rounded mb-3 is-info is-light is-justify-content-space-between">
                  <small>Current Jackpot</small>
                  <span className="has-text-weight-bold">{numberWithDecimals(match.totalAmount, match.tokenDecimals).toFormat(2)}</span>
                </span> */}
                {/* <span className="tag is-large is-rounded mb-3 is-info is-light is-justify-content-space-between">
                  <small>Jackpot after Bet</small>
                  <span className="has-text-weight-bold">{numberWithDecimals(match.totalAmount, match.tokenDecimals).plus(newAmount()).toFormat(2)}</span>
                </span> */}
                <span className="tag is-large is-rounded mb-3 is-success is-light is-justify-content-space-between">
                  <small>Potential Earnings</small>
                  <span className="has-text-weight-bold is-size-4">{potentialEarnings()}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className="modal-close is-large" aria-label="close" onClick={handleModalClose} />
    </div>
  );
}

export default BetOnMatchModal;
