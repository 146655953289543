import React, { useContext, useState } from 'react';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'wouter';

import { WalletContext } from '../../../context/wallet';
import { classNames } from '../../../services/utils';
import useAppStore from '../../../store/use-app-store';

import useLocation from '../../../hooks/use-location';

const Header = () => {
  const { openConnectModal } = useContext(WalletContext);
  const [
    walletConnected,
    disconnect
  ] = useAppStore((state) => [
    state.walletState.walletConnected,
    state.disconnect
  ]);

  const [isActive, setIsActive] = useState(false);
  const [location, , ] = useLocation();

  const toggleNav = (evt) => {
    evt.preventDefault();
    setIsActive(prevState => !prevState);
  }

  const blurOnClick = (evt) => {
    evt.target.blur();
    if (isActive) {
      setIsActive(prevState => !prevState);
    }
  }

  const handleConnect = (evt) => {
    evt.preventDefault();
    openConnectModal();
    blurOnClick(evt);
  }

  const handleDisconnect = (evt) => {
    evt.preventDefault();
    if (walletConnected) {
      disconnect();
    }
    blurOnClick(evt);
  }

  const renderConnectButton = () => {
    if (walletConnected) {
      return (
        <div className="navbar-item has-dropdown is-hoverable">
          <div className="navbar-link is-arrowless">
            <span className="icon-text">
              <span className="icon">
                <FontAwesomeIcon icon={faWallet} />
              </span>
              <span>{`${walletConnected.slice(0, 4)}...${walletConnected.slice(walletConnected.length - 4)}`}</span>
            </span>
          </div>
          <div className="navbar-dropdown is-right">
            <Link to="/profile" className="navbar-item" onClick={blurOnClick}>
              Profile
            </Link>
            <a href="/" onClick={handleDisconnect} className="navbar-item">
              Logout
            </a>
            <hr className="navbar-divider" />
            <a href="https://quickswap.exchange/#/swap?outputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174" target="_blank" rel="noreferrer" className="navbar-item">
              Get USDC
            </a>
          </div>
        </div>
      );
    }

    return (
      <a href="/" onClick={handleConnect} className="navbar-link is-arrowless">
        <span className="icon-text">
          <span className="icon">
            <FontAwesomeIcon icon={faWallet} />
          </span>
          <span>Connect</span>
        </span>
      </a>
    );
  }

  return (
    <nav className="navbar is-black is-fixed-top" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href="https://degoverse.bet">
          <figure className="image is-24by24">
            <img src="/images/logo.png" alt="DegoVerse" style={{ width: '24px' }} />
          </figure>
          <span className="has-text-success is-family-sans-serif ml-2 is-size-4">DegoVerse</span>
        </a>
    
        <button
          type="button"
          className="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          onClick={toggleNav}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>
    
      <div className={classNames('navbar-menu', isActive && 'is-active')}>
        <div className="navbar-start">
          <div className="navbar-item has-dropdown is-hoverable">
            <div className="navbar-item has-text-light is-clickable">
              NFT Sale
            </div>
            <div className="navbar-dropdown is-dark">
              <Link to="/sale" onClick={blurOnClick} className="navbar-item">
                Players
              </Link>
              <Link to="/sport-sale" onClick={blurOnClick} className="navbar-item">
                Sports
              </Link>
              <Link to="/game-card-sale" onClick={blurOnClick} className="navbar-item">
                Game Cards
              </Link>
            </div>
          </div>
          <Link to="/world-cup-game" onClick={blurOnClick} className={classNames('navbar-item', 'has-text-success', location.pathname === 'world-cup' && 'is-active')}>
            World Cup Game
          </Link>
          <Link to="/trainings" onClick={blurOnClick} className={classNames('navbar-item', ['/trainings', '/training'].includes(location.pathname) && 'is-active')}>
            Training
          </Link>
          <Link to="/referrals" onClick={blurOnClick} className={classNames('navbar-item', location.pathname === '/referrals' && 'is-active')}>
            Referrals
          </Link>
          <a href="https://opensea.io/DegoVerse?tab=created" target="_blank" rel="noreferrer" className="navbar-item">
            Collections
          </a>
        </div>
        <div className="navbar-end">
          {renderConnectButton()}
        </div>
      </div>
    </nav>
  );
}

export default Header;
