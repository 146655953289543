import { classNames } from '../../../services/utils';
import useLocation from '../../../hooks/use-location';

const GameCardNftCard = ({ gameCard, children = null, hideQuantity = false, navigate = false }) => {
  const [, setLocation, ] = useLocation();

  return (
    <div
      className={classNames('card', 'mb-3', navigate && 'is-clickable')}
      onClick={() => navigate && setLocation(`/game-cards/${gameCard.id}`)}
    >
      <div className="card-image">
        <figure className="image">
          <img src={`https://degoverse.ams3.cdn.digitaloceanspaces.com/training-cards/${gameCard.id}.png`} alt={gameCard.name} />
        </figure>
      </div>
      <div className="card-content">
        <h3 className="subtitle has-text-centered mb-0">{gameCard.name} [{gameCard.id}] {hideQuantity ? '' : `(${gameCard.quantity})`}</h3>
      </div>
      {children}
    </div>
  );
}

export default GameCardNftCard;
