const contractsMainNet = new Map();

// GENERAL
contractsMainNet.set('multiCall', '0xEF3d4160E3d193E3d1494a4A9cF9eEA05E63a324');
contractsMainNet.set('matic', '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270');

// PROD
contractsMainNet.set('worldCup', '0xB7115015747fF9413661c63a155604823a1e5a01');
contractsMainNet.set('nftPlayer', '0xFA190F722784C13509B9D2104A6d3e9aD322481D');
contractsMainNet.set('sportNft', '0xe952EEA84a751E31748799954152736E15289e90');
contractsMainNet.set('gameCardNft', '0x0cE88Ed8D3027ad0debd641abe7383a21f1868AE');
contractsMainNet.set('sale', '0xf9b861d0d3376b5eff96Ecc8E7052bA959820830');
contractsMainNet.set('sportSale', '0xd04acf5e60Ef5B34FE18F407390Dc744904073d5');
contractsMainNet.set('gameCardSale', '0xBa315cB285a24C5EEcFACa58e1f79C4934c2De32');
contractsMainNet.set('nftAdmin', '0xEAD88Bb55ee0E50DC2c80a8112E484bFDF664EE4');
contractsMainNet.set('usdc', '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174');
contractsMainNet.set('training', '0x48e4dB4aC22463dd32bdeBDE72221B7e9A5223BA');
contractsMainNet.set('playerOperator', '0x4A440c03fd86c9C3ccaF1905Ae1D98D0F7e71518'); // informative purposes


// TEST
// contractsMainNet.set('worldCup', '0xE1189a8F6aA15259BA3Ee73cFEdD0C7C9a5cF8c5');
// contractsMainNet.set('sale', '0xD46107116f154A2fA6cEE18803b20d5C8b3f0760');
// contractsMainNet.set('sportSale', '0x4497419E568A4510230E1Ad8f92CA5ef1362E62e');
// contractsMainNet.set('gameCardSale', '0xc13aE36fB25C0872C08d7412768309Bc06be30fD');
// contractsMainNet.set('nftPlayer', '0x8F168Dc95BAF679fa110BB3A9ba875Fc80B1f1b7');
// contractsMainNet.set('nftAdmin', '0x0DF875a9570eDD7A5e6fc178941517F8a7b0C072');
// contractsMainNet.set('sportNft', '0x692Ab82e57D05d7268d20fFA26C7De84246bAAD7');
// contractsMainNet.set('gameCardNft', '0x66Ca78b63C4c7bdeEaCa9e1325050194aE647dF2');
// contractsMainNet.set('training', '0xAa65DAf46A9abF8080A3E7b005a2d78ef55869c3');
// contractsMainNet.set('usdc', '0x61429f72EDf0B4737fd0A46f837D6f2C8bcC607E'); // fake usdc
// contractsMainNet.set('playerOperator', '0x0b65f6DaBE6017de97d281Eb5251D4983F3ba766');


export { contractsMainNet };
