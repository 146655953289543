import { getAddress, loadContract, multiCall } from '../services/utils';
import nftPlayerAbi from '../config/abis/nft-player.json';
import asyncFetchPlayersData from './async-fetch-players-data';

const asyncFetchPlayers = async (prevPlayers, connectedWallet) => {
  let players = [];

  if (!connectedWallet) {
    return {players};
  }

  const nftPlayerContract = loadContract('nftPlayer', nftPlayerAbi);

  const nftPlayerAddress = getAddress('nftPlayer');
  const balance = await nftPlayerContract.balanceOf(connectedWallet);

  const idsCalls = [];

  // limit 100
  for (let i = 0; balance.gt(i) && i < 100; i++) {
    idsCalls.push({
      name: 'tokenOfOwnerByIndex',
      address: nftPlayerAddress,
      params: [connectedWallet, i]
    });
  }
  const idsRs = await multiCall(nftPlayerAbi, idsCalls);
  for (let i = 0; i < idsRs.length; i++) {
    let id = idsRs[i][0].toNumber();
    let player = prevPlayers.find((sp) => sp.id === id);
    players.push(Object.assign({...player}, {id}));
  }

  return {
    ...await asyncFetchPlayersData(players)
  }
}

export default asyncFetchPlayers;
