import React, { useContext, useMemo } from 'react';
import { faPlusCircle, faRocket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { classNames, numberWithDecimals } from '../../../../../../services/utils';
import BigNumber from 'bignumber.js';
import { WalletContext } from '../../../../../../context/wallet';
import useAppStore from '../../../../../../store/use-app-store';

const UserBet = ({ match, setBetOnMatch, setAddPlayersOnMatch }) => {
  const { openConnectModal } = useContext(WalletContext);

  const walletConnected = useAppStore((state) => state.walletState.walletConnected);

  const handleConnect = () => {
    if (!walletConnected) {
      openConnectModal();
    }
  }

  const total = useMemo(() => {
    return new BigNumber(match.userLocalWinAmount)
      .plus(match.userVisitorWinAmount)
      .plus(match.userTieAmount);
  }, [match.userLocalWinAmount, match.userVisitorWinAmount, match.userTieAmount]);

  const renderButtons = () => {
    if (!walletConnected) {
      return (
        <button
          onClick={handleConnect}
          className="button is-primary is-outlined is-rounded"
        >
          Connect
        </button>
      );

    }

    return (
      <button
        disabled={match.playingNow || match.finished}
        onClick={() => setBetOnMatch(match)}
        className="button is-primary is-outlined is-rounded mr-1"
      >
        <span className="icon">
          <FontAwesomeIcon icon={faPlusCircle} />
        </span>
        <span>Bet</span>
      </button>
    );
  }

  const renderBoost = (amount, powerAmount, bet) => {
    if (new BigNumber(amount).eq(0) || ((match.playingNow || match.finished) && new BigNumber(powerAmount).eq(0))) {
      return (
        <span style={{ minWidth: '78px' }} className="has-text-right" />
      );
    }

    let text = (
      <span className="icon-text">
        <span>boost</span>
        <span className="icon ml-0">
          <FontAwesomeIcon icon={faRocket} />
        </span>
      </span>
    );

    if (new BigNumber(powerAmount).gt(0)) {
      text = (
        <span style={{ minWidth: '78px' }} className={classNames('has-text-right', new BigNumber(powerAmount).gt(0) && 'has-text-weight-bold')}>
          +{new BigNumber(powerAmount).times(100).dividedBy(amount).toFormat(2)}%
        </span>
      );
    }

    return (
      <span style={{ minWidth: '78px' }} className="has-text-right">
        <a
          href="/"
          disabled={match.playingNow || match.finished || match.paused}
          onClick={(evt) => {
            evt.preventDefault();
            setAddPlayersOnMatch({...match, selectedBet: bet});
          }}
          className="has-text-success"
        >
          {text}
        </a>
      </span>
    );
  }

  return (
    <div className="column is-6-tablet is-3-desktop">
      <div className="is-flex is-align-items-center" style={{ height: '48px' }}>
        <h3 className="is-family-secondary has-text-primary mr-auto mb-0">
          Your Bet
        </h3>
        {renderButtons()}
      </div>
      <div className="is-flex is-align-items-center has-background-light p-1">
        <small className="mr-auto">Total:</small>
        <span className={classNames(total.gt(0) && 'has-text-weight-bold has-text-primary')}>
          {numberWithDecimals(total, match.tokenDecimals).toFormat(2)}
        </span>
        <span style={{ minWidth: '78px' }} className="has-text-right" />
      </div>
      <div className="is-flex is-align-items-center p-1">
        <small className="mr-auto">Win {match.localTeamShortName}:</small>
        <span className={classNames(new BigNumber(match.userLocalWinAmount).gt(0) && 'has-text-weight-bold has-text-primary')}>
          {numberWithDecimals(match.userLocalWinAmount, match.tokenDecimals).toFormat(2)}
        </span>
        {renderBoost(match.userLocalWinAmount, match.userLocalWinPowerAmount, '1')}
      </div>
      <div className="is-flex is-align-items-center has-background-light p-1">
        <small className="mr-auto">Win {match.visitorTeamShortName}:</small>
        <span className={classNames(new BigNumber(match.userVisitorWinAmount).gt(0) && 'has-text-weight-bold has-text-primary')}>
          {numberWithDecimals(match.userVisitorWinAmount, match.tokenDecimals).toFormat(2)}
        </span>
        {renderBoost(match.userVisitorWinAmount, match.userVisitorWinPowerAmount, '2')}
      </div>
      {match.matchType === 0 ? (
        <div className="is-flex is-align-items-center p-1">
          <small className="mr-auto">Tie:</small>
          <span className={classNames(new BigNumber(match.userTieAmount).gt(0) && 'has-text-weight-bold has-text-primary')}>
            {numberWithDecimals(match.userTieAmount, match.tokenDecimals).toFormat(2)}
          </span>
            {renderBoost(match.userTieAmount, match.userTiePowerAmount, '3')}
        </div>
      ) : null}
    </div>
  );
}

export default UserBet;
