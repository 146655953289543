import { loadContract } from '../services/utils';
import gameCardNftAbi from '../config/abis/game-card-nft.json';
import asyncFetchGameCardsData from './async-fetch-game-cards-data';

const asyncFetchGameCards = async (prevGameCards, connectedWallet) => {
  let gameCards = [];

  if (!connectedWallet) {
    return {gameCards};
  }

  const gameCardNftContract = loadContract('gameCardNft', gameCardNftAbi);

  const accounts = [];
  const ids = [];
  for (let i = 0; i < 75; i++) {
    accounts.push(connectedWallet);
    ids.push(i);
  }
  const balance = await gameCardNftContract.balanceOfBatch(accounts, ids);

  for (let i = 0; i < balance.length; i++) {
    if (balance[i].gt(0)) {
      const gameCard = prevGameCards.find((gc) => i === gc.id) || {};
      gameCards.push(Object.assign({
        ...gameCard
      }, {
        id: i,
        quantity: balance[i].toNumber()
      }));
    }
  }

  return {
    ...await asyncFetchGameCardsData(gameCards)
  };
}

export default asyncFetchGameCards;
