import React, { useState } from 'react';
// import { request, gql } from 'graphql-request'
// import { hexlify } from 'ethers/lib/utils';

// import BigNumber from 'bignumber.js';
// import { Link } from 'wouter';
import Loading from '../../shared/components/loading';
import useAppStore from '../../store/use-app-store';

import ProfilePlayers from './components/profile-players';
import ProfileSports from './components/profile-sports';
import ProfileGameCards from './components/profile-game-cards';
import { classNames } from '../../services/utils';

const ProfilePage = () => {
  // const walletConnected = useAppStore((state) => state.walletState.walletConnected);

  const [
    playersLength,
    sportsLength,
    gameCardsLength,
    loadingPlayers,
    loadingSports,
    loadingGameCards
  ] = useAppStore((state) => [
    state.playersState.players.length,
    state.sportsState.sports.length,
    state.gameCardsState.gameCards.length,
    state.loadingPlayers,
    state.loadingSports,
    state.loadingGameCards
  ]);
  // const [purchases, setPurchases] = useState([]);
  // const [sportPurchases, setSportPurchases] = useState([]);
  // const [gameCardPurchases, setGameCardPurchases] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState('players');

  // useEffect(() => {
  //   const fetchProfile = async () => {
  //     if (!walletConnected) {
  //       return;
  //     }

  //     const queryHome = gql`
  //       {
  //         user(id: "${hexlify(walletConnected)}") {
  //           purchases {
  //             id
  //             saleQty
  //             amountUsd
  //             purchasedAtTimestamp
  //             discount {
  //               per
  //             }
  //             salePack {
  //               id
  //               qty
  //               price
  //             }
  //           }
  //           sportPurchases {
  //             id
  //             saleQty
  //             amountUsd
  //             purchasedAtTimestamp
  //             discount {
  //               per
  //             }
  //             salePack {
  //               id
  //               qty
  //               price
  //             }
  //           }
  //           gameCardPurchases {
  //             id
  //             saleQty
  //             amountUsd
  //             purchasedAtTimestamp
  //             discount {
  //               per
  //             }
  //             salePack {
  //               id
  //               qty
  //               price
  //             }
  //           }
  //         }
  //       }
  //     `;

  //     try {
  //       const rs = await request(process.env.REACT_APP_GRAPHQL_URL, queryHome);
  //       const { user } = rs;

  //       if (user) {
  //         setPurchases(user.purchases);
  //         setSportPurchases(user.sportPurchases);
  //         setGameCardPurchases(user.gameCardPurchases);
  //       }

  //       setLoading(false);
  //     } catch(error) {
  //       console.log(error);
  //     };
  //   }

  //   const interval = setInterval(fetchProfile, 10000);

  //   fetchProfile();

  //   return () => clearInterval(interval);
  // }, [walletConnected, setPurchases, setLoading, setSportPurchases, setGameCardPurchases]);

  if (loadingPlayers || loadingSports || loadingGameCards) {
    return <Loading />;
  }

  return (
    <>
      <header className="hero is-dark" style={{ backgroundImage: 'url(/images/banner.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <div className="hero-body has-text-centered">
          <div className="container">
            <h1 className="title has-text-light">Your Profile</h1>
          </div>
        </div>
        <div className="hero-foot">
        <div className="tabs is-centered is-boxed">
            <ul>
              <li className={classNames(tab === 'players' && 'is-active')}>
                <a href="/" onClick={(evt) => { evt.preventDefault(); setTab('players'); }}>
                  Players ({playersLength})
                </a>
              </li>
              <li className={classNames(tab === 'sports' && 'is-active')}>
                <a href="/" onClick={(evt) => { evt.preventDefault(); setTab('sports'); }}>
                  Sport NFTs ({sportsLength})
                </a>
              </li>
              <li className={classNames(tab === 'gameCards' && 'is-active')}>
                <a href="/" onClick={(evt) => { evt.preventDefault(); setTab('gameCards'); }}>
                  Game Cards ({gameCardsLength})
                </a>
              </li>
              {/* <li className={classNames(tab === 'purchases' && 'is-active')}>
                <a href="/" onClick={(evt) => { evt.preventDefault(); setTab('purchases'); }}>
                  Purchases ({purchases.length + sportPurchases.length + gameCardPurchases.length})
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </header>
      <main role="main" className="section">
        <div className="container">
          <section className={classNames(tab !== 'players' && 'is-hidden')}>
            <ProfilePlayers />
          </section>
          <section className={classNames(tab !== 'sports' && 'is-hidden')}>
            <ProfileSports />
          </section>
          <section className={classNames(tab !== 'gameCards' && 'is-hidden')}>
            <ProfileGameCards />
          </section>
          {/* <section className={classNames(tab !== 'purchases' && 'is-hidden')}>
            <div className="columns is-centered">
              <div className="column is-half">
                {(purchases.length + sportPurchases.length + gameCardPurchases.length) === 0 ? (
                  <article className="message is-warning">
                    <div className="message-body">
                      Purchase list is empty. <Link to="/sale">Buy here</Link> to win prizes!
                    </div>
                  </article>
                ) : (
                  <table className="table is-fullwidth">
                    <thead>
                      <tr>
                        <th className="has-background-dark has-text-light">Type</th>
                        <th className="has-background-dark has-text-light">TX</th>
                        <th className="has-background-dark has-text-light has-text-right">Qty</th>
                        <th className="has-background-dark has-text-light has-text-right">Amount</th>
                        <th className="has-background-dark has-text-light has-text-right">Purchased At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {purchases.map(purchase => (
                        <tr key={`purchase-${purchase.id}`}>
                          <td><span className="has-text-success">Player</span></td>
                          <td>
                            <Link to={`/purchases/${purchase.id}`}>
                              {`${purchase.id.slice(0, 4)}...${purchase.id.slice(purchase.id.length - 4)}`}
                            </Link>
                          </td>
                          <td className="has-text-right">{purchase.saleQty}</td>
                          <td className="has-text-right">
                            ${new BigNumber(purchase.amountUsd).dividedBy(new BigNumber(10).pow(6)).toFormat(2)}
                          </td>
                          <td className="has-text-right">
                            {new Date(purchase.purchasedAtTimestamp * 1000).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' })}
                          </td>
                        </tr>
                      ))}
                      {sportPurchases.map(sportPurchase => (
                        <tr key={`sportPurchase-${sportPurchase.id}`}>
                          <td><span className="has-text-danger">Sport</span></td>
                          <td>
                            <Link to={`/sport-purchases/${sportPurchase.id}`}>
                              {`${sportPurchase.id.slice(0, 4)}...${sportPurchase.id.slice(sportPurchase.id.length - 4)}`}
                            </Link>
                          </td>
                          <td className="has-text-right">{sportPurchase.saleQty}</td>
                          <td className="has-text-right">
                            ${new BigNumber(sportPurchase.amountUsd).dividedBy(new BigNumber(10).pow(6)).toFormat(2)}
                          </td>
                          <td className="has-text-right">
                            {new Date(sportPurchase.purchasedAtTimestamp * 1000).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' })}
                          </td>
                        </tr>
                      ))}
                      {gameCardPurchases.map(gameCardPurchase => (
                        <tr key={`gameCardPurchase-${gameCardPurchase.id}`}>
                          <td><span className="has-text-info">Game Card</span></td>
                          <td>
                            <Link to={`/game-card-purchases/${gameCardPurchase.id}`}>
                              {`${gameCardPurchase.id.slice(0, 4)}...${gameCardPurchase.id.slice(gameCardPurchase.id.length - 4)}`}
                            </Link>
                          </td>
                          <td className="has-text-right">{gameCardPurchase.saleQty}</td>
                          <td className="has-text-right">
                            ${new BigNumber(gameCardPurchase.amountUsd).dividedBy(new BigNumber(10).pow(6)).toFormat(2)}
                          </td>
                          <td className="has-text-right">
                            {new Date(gameCardPurchase.purchasedAtTimestamp * 1000).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' })}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </section> */}
        </div>
      </main>
    </>
  );
}

export default ProfilePage;
