import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Countdown from 'react-countdown';
import { classNames } from '../../../../../../services/utils';

const MatchInfo = ({ match }) => {
  const statusClass = () => {
    if (match.finished) {
      return 'is-danger';
    }

    if (match.playingNow) {
      return 'is-success';
    }

    return 'is-info';
  }

  const showTime = () => {
    return (
      <>
        <FontAwesomeIcon icon={faClock} className="mx-2" />
        <span>{match.timeMinute + (match.timeInjuryTime || 0)}:{match.timeSecond || '00'}</span>
        {match.timeAddedTime ? (<span className="ml-2">+{match.timeAddedTime}</span>) : null}
      </>
    );
  }

  const renderStatusOrCountdown = () => {
    if (match.status !== 'Not Started') {
      return (
        <span className={classNames('tag', 'is-light', statusClass())}>
          {match.status}{match.status === 'Live' && showTime()}
        </span>
      );
    }

    return (
      <span className="tag is-light is-info">
        <span className="mr-1">Game starts in</span> <Countdown date={match.startTime * 1_000} />
      </span>
    );
  }

  return (
    <div className="column is-6-tablet is-4-desktop has-background-light">
      <div className="has-text-centered">
        <span className="tag is-primary is-light">
          {new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(match.startTime * 1_000)}
        </span>
      </div>
      <div className="is-flex is-align-items-center is-justify-content-center py-2">
        <div className="has-text-centered mr-2">
          <figure className="image is-64x64 mx-auto">
            <img className="is-rounded" src={`/images/flags/${match.localTeamName}.png`} style={{ height: '100%', objectFit: 'cover', border: '1px solid #ddd' }} alt={match.localTeamName} />
          </figure>
          <strong className="is-family-secondary has-text-primary">{match.localTeamShortName}</strong>
        </div>
        <strong className="is-family-secondary has-text-primary is-size-2">
          <span className="mr-1">
            {match.localTeamPenScore !== null ? (
              <small className="mr-1">
                ({match.localTeamPenScore})
              </small>
            ) : null}
            {match.localTeamScore}
          </span>:
          <span className="ml-1">
            {match.visitorTeamScore}
            {match.visitorTeamPenScore !== null ? (
              <small className="ml-1">
                ({match.visitorTeamPenScore})
              </small>
            ) : null}
          </span>
        </strong>
        <div className="has-text-centered ml-2">
          <figure className="image is-64x64 mx-auto">
            <img className="is-rounded" src={`/images/flags/${match.visitorTeamName}.png`} style={{ height: '100%', objectFit: 'cover', border: '1px solid #ddd' }} alt={match.visitorTeamName} />
          </figure>
          <strong className="is-family-secondary has-text-primary">{match.visitorTeamShortName}</strong>
        </div>
      </div>
      <div className="has-text-centered is-size-7">
        {renderStatusOrCountdown()}
      </div>
    </div>
  );
}

export default MatchInfo;
