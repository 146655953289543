import React, { useEffect, useState } from 'react';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactMarkdown from 'react-markdown';

import SportNftCard from '../../shared/components/sport-nft-card';
import { getAddress } from '../../services/utils';
import Loading from '../../shared/components/loading';
import useAppStore from '../../store/use-app-store';
import asyncFetchSportsData from '../../blockchain/async-fetch-sports-data';

// const Model = ({ token }) => {
//   const modelRef = useRef();

//   useEffect(() => {
//     const handleResize = () => {
//       modelRef.current.style.width = `${modelRef.current.parentNode.offsetWidth}px`;
//       modelRef.current.style.height = `${modelRef.current.parentNode.offsetWidth * 9 / 16}px`;
//     }

//     window.addEventListener('resize', handleResize);

//     handleResize();

//     return () => {
//       window.removeEventListener('resize', handleResize);
//     }
//   });

//   return (
//     <div
//       className="is-flex is-justify-content-center" style={{ width: '100%' }}
//     >
//       <model-viewer
//         alt={token.name}
//         // camera-orbit="180deg 90deg"
//         touch-action="pan-y"
//         src={token.animationUrl}
//         camera-controls
//         loading="eager"
//         poster={token.image}
//         ref={(ref) => {
//           modelRef.current = ref;
//         }}
//         ></model-viewer>
//     </div>
//   );
// }

const SportPage = ({ id }) => {
  const isOwner = useAppStore((state) => state.sportsState.sports.some((sport) => sport.id === Number(id)));

  const [sportNft, setSportNft] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPlayer = async () => {
      const { sports } = await asyncFetchSportsData([{ id }]);
      setSportNft(sports[0]);
      setLoading(false);
    }

    fetchPlayer();
  }, [id, setSportNft, setLoading]);

  // const renderAnimation = () => {
  //   if (sportNft.animationUrl.endsWith('.mp4')) {
  //     return (
  //       <figure className="image is-16by9">
  //         <video
  //           width="100%"
  //           height="100%"
  //           controls
  //           muted="muted"
  //           autoplay="autoplay"
  //           loop="loop"
  //         >
  //           <source src={sportNft.animationUrl} type="video/mp4" />
  //         </video>
  //       </figure>
  //     );
  //   }

  //   if (sportNft.animationUrl.endsWith('.glb')) {
  //     return <Model token={sportNft} />
  //   }

  //   return (
  //     <figure className="image">
  //       <img src={sportNft.image} alt={sportNft.name} />
  //     </figure>
  //   );
  // }

  if (loading) {
    return <Loading />;
  }  

  return (
    <main role="main" className="section has-background-light">
      <div className="container">
        {isOwner ? (
          <div className="buttons is-justify-content-end">
            <a href={`https://opensea.io/assets/matic/${getAddress('sportNft')}/${sportNft.id}`} target="_blank" rel="noreferrer" className="button is-primary is-rounded is-outlined">
              <span className="icon-text">
                <span>TRADE</span>
                <span className="icon">
                  <FontAwesomeIcon icon={faExternalLink} />
                </span>
              </span>
            </a>
          </div>
        ) : null}
        <div className="columns is-centered">
          <div className="column is-one-third">
            <SportNftCard sportNft={sportNft} />
            {/* {renderAnimation()} */}
          </div>
          <div className="column is-one-third">
            <h1 className="title">{sportNft.name}</h1>
            <div className="message is-info">
              <div className="message-body content">
                <ReactMarkdown>{sportNft.description}</ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default SportPage;
