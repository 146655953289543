import useAppStore from '../store/use-app-store';
import usePollingEffect from './use-polling-effect';

const useWorldCupGameContract = () => {
  const [
    walletConnected,
    loadingMatches,
    fetchMatches,
    matches
  ] = useAppStore((state) => [
    state.walletState.walletConnected,
    state.loadingMatches,
    state.fetchMatches,
    state.matchesState.matches
  ]);

  usePollingEffect(async () => {
    await fetchMatches(walletConnected);
  }, [walletConnected, fetchMatches], 3_000);

  return {
    walletConnected,
    loading: loadingMatches,
    matches
  }
}

export default useWorldCupGameContract;
