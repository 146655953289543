import { useEffect, useState } from 'react';
import useAppStore from '../store/use-app-store';

const useTrainingsContract = () => {
  const [started, setStarted] = useState(false);
  const [
    trainingCenters,
    loading
  ] = useAppStore((state) => [
    state.trainingState.trainingCenters,
    state.loadingTraining
  ]);

  useEffect(() => {
    const checkStarted = () => {
      setStarted(prevState => prevState || Date.now() > (Number(process.env.REACT_APP_TRAINING_START_AT) * 1000));
    }

    const interval = setInterval(checkStarted, 1000);

    checkStarted();
    
    return () => clearInterval(interval);
  }, []);

  return {
    trainingCenters,
    loading,
    started
  }
}

export default useTrainingsContract;
