import React, { createContext, useState } from 'react';

export const ToastContext = createContext();

export const ToastProvider = (props) => {
  const [list, setList] = useState([]);

  const addToast = (text, kind) => {
    const uid = Date.now().toString(36) + Math.random().toString(36).substr(2);
    setList([...list, { uid, text, kind }]);
  }

  const deleteToast = (uid) => {
    const index = list.findIndex(toast => toast.uid === uid);
    list.splice(index, 1);
    setList([...list]);
  }

  return (
    <ToastContext.Provider value={{ addToast, deleteToast, list }}>
      {props.children}
    </ToastContext.Provider>
  );
}
