import { useEffect } from 'react';
import BigNumber from 'bignumber.js/bignumber';

import Layout from './layout';

import './App.scss';

import { WalletProvider } from './context/wallet';
import { ToastProvider } from './context/toast';

import useAppStore from './store/use-app-store';
import usePollingEffect from './hooks/use-polling-effect';

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
  ROUNDING_MODE: BigNumber.ROUND_DOWN
});

const LoadState = () => {
  const [
    walletConnected,
    selectedProvider,
    connection,
    fetchTraining,
    fetchPlayers,
    fetchSports,
    fetchGameCards,
    // fetchPurchases,
    // fetchSale
  ] = useAppStore((state) => [
    state.walletState.walletConnected,
    state.walletState.selectedProvider,
    state.connection,
    state.fetchTraining,
    state.fetchPlayers,
    state.fetchSports,
    state.fetchGameCards,
    // state.fetchPurchases,
    // state.fetchSale
  ]);

  // Fetch user data each 30 seconds
  usePollingEffect(async () => {
    await fetchPlayers(walletConnected);
    await fetchSports(walletConnected);
    await fetchGameCards(walletConnected);
    // await fetchPurchases(walletConnected);
  }, [walletConnected], 30_000);

  // Fetch public data one time
  useEffect(() => {
    fetchTraining();
    // fetchSale();
  }, [fetchTraining]);

  useEffect(() => {
    connection();
    if (selectedProvider) {
      if (selectedProvider === 'METAMASK') {
        window.ethereum.on('chainChanged', async (chainId) => {
          console.log('chainId', chainId);
          window.location.reload();
        });
        window.ethereum.on('accountsChanged', (accounts) => {
          console.log('accounts');
          if (Array.isArray(accounts)) {
            console.log('accountsChanged', accounts[0]);
            connection();
          }
        });
        window.ethereum.on('disconnect', (code, reason) => {
          console.log('### disconnected', code, reason);
          connection();
        });
      }
    }

    return () => {
      window.ethereum?.removeAllListeners();
    }
  }, [selectedProvider, connection]);

  return (<></>);
}

const App = () => {
  return (
    <WalletProvider>
      <ToastProvider>
        <LoadState />
        <Layout />
      </ToastProvider>
    </WalletProvider>
  );
}

export default App;
