import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { classNames } from '../../../../services/utils';

const Filters = ({ stakedOnly, setStakedOnly, pendingClaim, setPendingClaim, filter, setFilter, search, setSearch }) => {
  const onToggleStakedOnly = () => {
    setStakedOnly(prevState => !prevState);
    saveFilters({ stakedOnly: !stakedOnly });
  }

  const onTogglePendingClaim = () => {
    let fil = filter;
    if (!pendingClaim && filter !== 'finished') {
      fil = 'finished';
      setFilter(fil);
    }
    setPendingClaim(prevState => !prevState);
    saveFilters({ pendingClaim: !pendingClaim, filter: fil });
  }

  const onSearchChange = (evt) => {
    const { target } = evt;
    setSearch(target.value);
    saveFilters({ search: target.value });
  }

  const saveFilters = (values) => {
    localStorage.setItem('worldCupFilters', JSON.stringify({
      filter,
      stakedOnly,
      search,
      ...values,
    }));
  }

  return (
    <div className="columns is-mobile is-multiline mb-6">
      <div className="column is-12-mobile is-narrow-desktop">
        <div className="select is-hidden-desktop is-rounded is-fullwidth">
          <select
            value={filter}
            onChange={(evt) => {
              let pc = pendingClaim;
              if (evt.target.value !== 'finished' && pendingClaim) {
                setPendingClaim(false);
                pc = false;
              }
              setFilter(evt.target.value);
              saveFilters({ filter: evt.target.value, pendingClaim: pc });
            }}
          >
            {['active', 'playing', 'finished', 'all'].map((fil) => (
              <option key={`select-option-${fil}`} value={fil}>{fil.toUpperCase()}</option>
            ))}
          </select>
        </div>
        <div className="buttons has-addons is-hidden-touch">
          {['active', 'playing', 'finished', 'all'].map((fil) => (
            <button
              key={`tab-option-${fil}`}
              onClick={() => {
                let pc = pendingClaim;
                if (fil !== 'finished' && pendingClaim) {
                  setPendingClaim(false);
                  pc = false;
                }
                setFilter(fil);
                saveFilters({ filter: fil, pendingClaim: pc });
              }}
              className={classNames('button', 'is-rounded', fil === filter && 'is-success')}
            >
              {fil.toUpperCase()}
            </button>
          ))}
        </div>
      </div>
      <div className="column is-half-mobile is-narrow">
        <div className="field">
          <input id="stakedOnly" type="checkbox" name="stakedOnly" className="switch is-rounded is-outlined is-success" checked={stakedOnly} onChange={onToggleStakedOnly} />
          <label className="has-text-light" htmlFor="stakedOnly">My Bets</label>
        </div>
      </div>
      <div className="column is-half-mobile">
        <div className="field">
          <input id="pendingClaim" type="checkbox" name="pendingClaim" className="switch is-rounded is-outlined is-success" checked={pendingClaim} onChange={onTogglePendingClaim} />
          <label className="has-text-light" htmlFor="pendingClaim">Pending Claim</label>
        </div>
      </div>
      <div className="column is-12-mobile is-narrow-desktop">
        <div className="field">
          <p className="control has-icons-left">
            <input className="input is-rounded" type="text" placeholder="Search..." value={search} onChange={onSearchChange} />
            <span className="icon is-left">
              <FontAwesomeIcon icon={ faSearch } />
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Filters;
