import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import erc20Abi from '../config/abis/erc20.json';
import nftPlayerAbi from '../config/abis/nft-player.json';
import { getAddress, multiCall } from '../services/utils';

import useAppStore from '../store/use-app-store';

const nftPlayerAddress = getAddress('nftPlayer');
const nftAdminAddress = getAddress('nftAdmin');
const saleAddress = getAddress('sale');
const usdcAddress = getAddress('usdc');

const useSaleModalContract = () => {
  const [
    walletConnected,
    signer
  ] = useAppStore((state) => [
    state.walletState.walletConnected,
    state.walletState.signer
  ]);
  const [saleState, setSaleState] = useState({
    loading: true,
    decimals: 0,
    balanceOf: 0,
    allowance: 0,
    nftRemaining: 0,
    nftBalanceOf: 0
  });
  const [reload, setReload] = useState(0);

  useEffect(() => {
    const fetchSaleModal = async () => {
      if (!walletConnected) {
        return;
      }

      const erc20Calls = [
        {
          address: usdcAddress,
          name: 'decimals',
        },
        {
          address: usdcAddress,
          name: 'balanceOf',
          params: [walletConnected],
        },
        {
          address: usdcAddress,
          name: 'allowance',
          params: [
            walletConnected,
            saleAddress,
          ],
        }
      ];

      const [
        decimals,
        balanceOf,
        allowance
      ] = await multiCall(erc20Abi, erc20Calls);

      const nftPlayerCalls = [
        {
          address: nftPlayerAddress,
          name: 'balanceOf',
          params: [walletConnected]
        },
        {
          address: nftPlayerAddress,
          name: 'balanceOf',
          params: [nftAdminAddress],
        },
      ];

      const [
        nftBalanceOf,
        nftRemaining
      ] = await multiCall(nftPlayerAbi, nftPlayerCalls);

      setSaleState({
        loading: false,
        decimals,
        balanceOf: new BigNumber(balanceOf).div(new BigNumber(10).pow(decimals)).toJSON(),
        allowance: new BigNumber(allowance).toJSON(),
        nftBalanceOf: new BigNumber(nftBalanceOf).toJSON(),
        nftRemaining: new BigNumber(nftRemaining).toJSON(),
      });
    }

    fetchSaleModal();
  }, [walletConnected, setSaleState, reload]);

  return {
    walletConnected,
    saleState,
    signer,
    setReload,
  }
}

export default useSaleModalContract;
