import React, { createContext, useState } from 'react';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { getWalletAddress, attachWalletListeners } from '../blockchain/commons';
import { classNames } from '../services/utils';
import useAppStore from '../store/use-app-store';

export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [isActive, setIsActive] = useState(false);

  const [
    walletConnected,
    connect
  ] = useAppStore((state) => [
    state.walletState.walletConnected,
    state.connect
  ]);

  const openConnectModal = () => {
    setIsActive(true);
  }

  const closeConnectModal = () => {
    setIsActive(false);
  }

  const handleConnect = (evt) => {
    evt.preventDefault();
    if (!walletConnected) {
      const {currentTarget} = evt;
      connect(currentTarget.dataset.provider);
      setIsActive(false);
    }
  }

  return (
    <WalletContext.Provider value={{openConnectModal}}>
      {children}
      <div className={classNames('modal', isActive && 'is-active')}>
        <div className="modal-background" onClick={closeConnectModal} />
        <div className="modal-content">
          <div className="box">
            <div className="columns is-mobile">
              <div className="column">
                <h3 className="title">
                  <span className="icon-text">
                    <span className="icon">
                      <FontAwesomeIcon icon={faWallet} />
                    </span>
                    <span>Connect with</span>
                  </span>
                </h3>
                <div className="buttons">
                  <button
                    type="button"
                    className="button is-primary is-outlined is-rounded"
                    data-provider="METAMASK"
                    onClick={handleConnect}
                  >
                    METAMASK
                  </button>
                  <button
                    type="button"
                    className="button is-primary is-outlined is-rounded"
                    data-provider="METAMASK"
                    onClick={handleConnect}
                  >
                    TRUST WALLET
                   </button>
                  <button
                    type="button"
                    className="button is-primary is-outlined is-rounded"
                    data-provider="WALLET_CONNECT"
                    onClick={handleConnect}
                  >
                    WALLET CONNECT
                  </button>
                </div>
              </div>
              <div className="column is-narrow">
                <figure className="image is-128x128 is-hidden-mobile">
                  <img src="/images/logo.png" alt="DegoVerse" />
                </figure>
                <figure className="image is-48x48 is-hidden-tablet">
                  <img src="/images/logo.png" alt="DegoVerse" />
                </figure>
              </div>
            </div>
          </div>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={closeConnectModal} />
      </div>
    </WalletContext.Provider>
  );
}
