import { getAddress, loadContract, multiCall } from '../services/utils';
import trainingAbi from '../config/abis/training.json';

const asyncFetchTraining = async () => {
  let trainingCenters = [];
  let sportItems = [];
  let gameCardItems = [];

  const trainingContract = loadContract('training', trainingAbi);

  const trainingAddress = getAddress('training');

  const tcLength = await trainingContract.tcLength();

  const trainingCenterCalls = [];
  for (let i = 0; tcLength.gt(i); i++) {
    trainingCenterCalls.push({
      name: 'trainingCenters',
      address: trainingAddress,
      params: [i]
    });
  }
  const trainingCentersRs = await multiCall(trainingAbi,  trainingCenterCalls);

  for (let i = 0; i < trainingCentersRs.length; i++) {
    trainingCenters.push({
      id: i,
      fee: trainingCentersRs[i][0].toNumber(),
      qtyAllow: trainingCentersRs[i][1].toNumber()
    });
  }

  const sportItemsLength = 5;

  const sportItemsCalls = [];
  for (let i = 1; i <= sportItemsLength; i++) {
    sportItemsCalls.push({
      name: 'getSportItem',
      address: trainingAddress,
      params: [i]
    });
  }
  const sportItemsRs = await multiCall(trainingAbi, sportItemsCalls);

  for (let i = 0; i < sportItemsRs.length; i++) {
    sportItems.push({
      rarity: sportItemsRs[i][0].toNumber(),
      power: sportItemsRs[i][1].toNumber(),
      distribution: [
        sportItemsRs[i][2].toNumber(),
        sportItemsRs[i][3].toNumber(),
        sportItemsRs[i][4].toNumber(),
        sportItemsRs[i][5].toNumber(),
        sportItemsRs[i][6].toNumber()
      ]
    });
  }

  const gameCardItemsLength = 75;

  const gameCardItemsCalls = [];
  for (let i = 0; i < gameCardItemsLength; i++) {
    gameCardItemsCalls.push({
      name: 'getGameCardItem',
      address: trainingAddress,
      params: [i]
    });
  }
  const gameCardItemsRs = await multiCall(trainingAbi, gameCardItemsCalls);

  for (let i = 0; i < gameCardItemsRs.length; i++) {
    gameCardItems.push({
      id: gameCardItemsRs[i][0].toNumber(),
      distribution: [
        gameCardItemsRs[i][1].sub(1e12).toNumber(),
        gameCardItemsRs[i][2].sub(1e12).toNumber(),
        gameCardItemsRs[i][3].sub(1e12).toNumber(),
        gameCardItemsRs[i][4].sub(1e12).toNumber(),
        gameCardItemsRs[i][5].sub(1e12).toNumber()
      ]
    });
  }

  return {
    trainingCenters,
    sportItems,
    gameCardItems
  };
}

export default asyncFetchTraining;
