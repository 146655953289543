import React from 'react';
import { Link } from 'wouter';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SportNftCard from '../../../../shared/components/sport-nft-card';
import useAppStore from '../../../../store/use-app-store';

const ProfileSports = () => {
  const [
    loadingSports,
    sports
  ] = useAppStore((state) => [
    state.loadingSports,
    state.sportsState.sports
  ]);

  return (
    <div className="columns is-centered is-multiline">
      {loadingSports ? (
        <div className="column is-narrow">
          <span className="icon is-large">
            <FontAwesomeIcon icon={faCog} spin size="4x" />
          </span>
        </div>
      ) : (
        sports.length === 0 ? (
          <div className="column is-half">
            <article className="message is-warning">
              <div className="message-body">
                Your bucket is empty. <Link to="/sport-sale">Buy here</Link> and became a superstar!
              </div>
            </article>
          </div>
        ) : (
          sports.map(sportNft => (
            <div key={`sports-${sportNft.id}`} className="column is-one-quarter">
              <SportNftCard sportNft={sportNft} navigate />
            </div>
          ))
        )
      )}
    </div>
  );
}

export default ProfileSports;
