import asyncFetchTraining from '../blockchain/async-fetch-training';
import trainingInitialState from '../states/training-initial-state';

const createTrainingSlice = (set, get) => ({
  trainingState: trainingInitialState,
  loadingTraining: true,
  updatingTraining: false,
  fetchTraining: async () => {
    if (!get().updatingTraining) {
      set((state) => ({ ...state, updatingTraining: true }));
      const rs = await asyncFetchTraining();
      set((_) => ({trainingState: rs, loadingTraining: false, updatingTraining: false}));
    }
  },
});

export default createTrainingSlice;
