import React, { useContext, useMemo, useState } from 'react';
import useAddPlayersContract from '../../../../hooks/use-add-players-contract';
import { classNames, getAddress, loadContract, numberWithDecimals } from '../../../../services/utils';

import { ToastContext } from '../../../../context/toast';

import worldCupAbi from '../../../../config/abis/world-cup.json';
import BigNumber from 'bignumber.js';
import { trainingErrorMessage } from '../../../training/utils';
import { getPowerBoost } from '../../../../services/players';
import { Link } from 'wouter';

const worldCupAddress = getAddress('worldCup');

const countries = ['-', 'Argentina','Belgium','Brazil','Cameroon','Canada','Croatia','Denmark','Ecuador','England','France','Germany','Ghana','Iran','Japan','Mexico','Morocco','Netherlands','Poland','Portugal','Qatar','Saudi Arabia','Senegal','Serbia','South Korea','Spain','Switzerland','Tunisia','Uruguay','US','Costa Rica','Australia','Wales'];
const positions = ['-', 'GK', 'DF', 'MD', 'FW'];

const getBirth = (player) => {
  const birthday = new Date(player.birth * 1000);

  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs);

  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

const AddPlayersModal = ({ match, handleModalClose }) => {
  const { addToast } = useContext(ToastContext);

  const {
    walletConnected,
    signer,
    players,
    usedPlayerIds
  } = useAddPlayersContract(match);

  const prevIds = () => {
    if (match.selectedBet === '1') {
      return match.userLocalWinPlayerIds;
    }

    if (match.selectedBet === '2') {
      return match.userVisitorWinPlayerIds;
    }

    if (match.selectedBet === '3') {
      return match.userTiePlayerIds;
    }

    return [];
  }

  const [ids, setIds] = useState(prevIds());
  const [isPending, setIsPending] = useState(false);

  const boost = useMemo(() => {
    let sum = 0;
    for (let i = 0; i < ids.length; i++) {
      let player = players.find(pl => pl.id.toString() === ids[i]);
      if (player) {
        let val = Number(getPowerBoost(player));
        if ([match.localTeamId, match.visitorTeamId].includes(player.country)) {
          val *= 1.05;
        }
        sum += val;
      }
    }

    return new BigNumber(sum).toFormat(2);
  }, [players, ids, match.localTeamId, match.visitorTeamId]);

  const total = () => {
    return new BigNumber(yourBet()).plus(Number(yourBet()) * boost / 100).toFormat(2);
  }

  const playersList = () => {
    const arr = players.map((player) => {
      let val = Number(getPowerBoost(player));
      if ([match.localTeamId, match.visitorTeamId].includes(player.country)) {
        val *= 1.05;
      }
      return { ...player, boost: val }
    });
    
    return arr.sort((a, b) => {
      if (a.boost > b.boost) {
        return -1;
      }
      if (a.boost < b.boost) {
        return 1;
      }

      return 0;
    });
  }

  const yourBet = () => {
    if (match.selectedBet === '1') {
      return numberWithDecimals(match.userLocalWinAmount, match.tokenDecimals).toFormat(2);
    }

    if (match.selectedBet === '2') {
      return numberWithDecimals(match.userVisitorWinAmount, match.tokenDecimals).toFormat(2);
    }

    if (match.selectedBet === '3') {
      return numberWithDecimals(match.userTieAmount, match.tokenDecimals).toFormat(2);
    }

    return '0.0';
  }

  const yourBetOption = () => {
    if (match.selectedBet === '1') {
      return `Win ${match.localTeamShortName}`;
    }

    if (match.selectedBet === '2') {
      return `Win ${match.visitorTeamShortName}`;
    }

    if (match.selectedBet === '3') {
      return 'Tie';
    }

    return '-';
  }

  const togglePlayer = (evt) => {
    const { target } = evt;
    if (ids.includes(target.value)) {
      setIds(prev => prev.filter(id => id !== target.value));
    } else {
      setIds(prev => [...prev, target.value]);
    }
  }

  const handleAddPlayers = async () => {
    if (!isValid || !walletConnected) {
      return;
    }

    setIsPending(true);
    let tx;
    try {
      const worldCupContract = loadContract(worldCupAddress, worldCupAbi, signer);
      tx = await worldCupContract.addPlayers(match.pid, ids, match.selectedBet);
      await tx.wait();
      addToast('NFT successfully added to your bet.', 'is-success');
      handleModalClose();
    } catch (e) {
      console.log(trainingErrorMessage(e));
      tx = { error: e.data?.message || e.message };
    }

    if(tx.error !== undefined) {
      console.log('error', tx.error);
      addToast('NFT unsuccessfully added to your bet. Please try again.', 'is-danger');
    }

    setIsPending(false);
  }

  const playerIsAvailable = (playerId) => {
    return !usedPlayerIds.some((id) => id === playerId.toString()) ||
      prevIds().some((id) => id === playerId.toString());
  }

  const isValid = useMemo(() => {
    return !match.playingNow && !match.finished && !match.paused;
  }, [match.playingNow, match.finished, match.paused]);

  const isEmpty = () => {
    return prevIds().length === 0 && ids.length === 0;
  }

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={handleModalClose} />
      <div className="modal-content">
        <div className="box">
          <h3 className="is-size-4 mb-3">
            Add NFT Players On Match - <span className="is-family-secondary has-text-primary">{match.localTeamShortName} vs {match.visitorTeamShortName}</span>
          </h3>
          <div className="divider is-size-5 mb-3">
            Select Players ({players.filter((pl) => playerIsAvailable(pl.id)).length})
          </div>
          {players.length > 0 ? (
            <table className="table is-fullwidth">
              <thead>
                <tr>
                  <th className="has-background-dark"></th>
                  <th className="has-background-dark has-text-light">Name</th>
                  <th className="has-background-dark has-text-light">Team</th>
                  <th className="has-background-dark has-text-light">Pos</th>
                  <th className="has-background-dark has-text-light has-text-right">Age</th>
                  <th className="has-background-dark has-text-light has-text-right">Boost %</th>
                </tr>
              </thead>
              <tbody>
                {playersList().map((player) => (
                  <tr key={`player-${player.id}`} className={classNames(ids.includes(player.id.toString()) && 'has-background-light')}>
                    <td>
                      <label className="checkbox" disabled={!playerIsAvailable(player.id)}>
                        <input
                          disabled={match.playingNow || match.finished || !playerIsAvailable(player.id)}
                          type="checkbox"
                          value={player.id}
                          checked={ids.includes(player.id.toString())}
                          onChange={togglePlayer}
                        />
                      </label>
                    </td>
                    <td>
                      <a href={`/players/${player.id}`} target="_blank" rel="noreferrer" title="Details">
                        Player #{player.id}
                      </a>
                    </td>
                    <td className="px-0 py-1">
                      <figure className="image is-32x32 mr-2">
                        <img className="is-rounded" src={`/images/flags/${countries[player.country]}.png`} style={{ height: '100%', objectFit: 'cover', border: '1px solid #ddd' }} alt={countries[player.country]} />
                      </figure>
                    </td>
                    <td>{positions[player.position]}</td>
                    <td className="has-text-right">{getBirth(player)}</td>
                    <td className="has-text-right">
                      <strong>{player.boost.toFixed(2)}</strong>%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <article className="message is-warning">
              <div className="message-body">
                Your bucket is empty. <Link to="/sale">Buy here</Link> and became a superstar!
              </div>
            </article>
          )}
          <div className="divider is-size-5 mb-3">
            Calculator
          </div>
          <div className="field has-addons mb-3">
            <p className="control">
              <a href="/" onClick={(evt) => evt.preventDefault()} className="button is-static">{yourBetOption()}</a>
            </p>
            <p className="control is-expanded">
              <input value={`${yourBet()} ${match.tokenSymbol}`} readOnly className="input has-text-right" type="text" placeholder="Your Bet" />
            </p>
            <p className="control">
              <a href="/" onClick={(evt) => evt.preventDefault()} className="button is-static">+</a>
            </p>
            <p className="control">
              <a href="/" onClick={(evt) => evt.preventDefault()} className="button is-static">%</a>
            </p>
            <p className="control is-expanded">
              <input value={boost} readOnly className="input has-text-right" type="text" placeholder="Boost %" />
            </p>
            <p className="control">
              <a href="/" onClick={(evt) => evt.preventDefault()} className="button is-static">=</a>
            </p>
            <p className="control">
              <input value={total()} readOnly className="input has-text-right" type="text" placeholder="Total" />
            </p>
          </div>
          <p>
            <button
              onClick={handleAddPlayers}
              disabled={isPending || !isValid || isEmpty()}
              className={classNames('button', 'is-primary', 'is-fullwidth', isPending && 'is-loading')}
            >
              Add Players
            </button>
          </p>
        </div>
      </div>
      <button className="modal-close is-large" aria-label="close" onClick={handleModalClose} />
    </div>
  );
}

export default AddPlayersModal;
