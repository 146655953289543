import BigNumber from 'bignumber.js';
import { useContext } from 'react';
import { Link } from 'wouter';
import { WalletContext } from '../../../context/wallet';
import useAppStore from '../../../store/use-app-store';

import trainingCentersJson from '../../../db/training-centers.json';
// import Model from '../model';

const TrainingCenterCard = ({ id, to, fee, qtyAllow, started }) => {
  const {
    openConnectModal,
  } = useContext(WalletContext);


  const walletConnected = useAppStore((state) => state.walletState.walletConnected);

  const handleConnect = () => {
    if (!walletConnected) {
      openConnectModal();
    }
  }

  const renderConnectOrGoButton = () => {
    if (!to) {  
      return null;
    }

    if (walletConnected) {
      if (started) {
        return (
          <Link to={to} className="button is-success is-rounded is-fullwidth">Go to Venue</Link>
        );
      }

      return <button disabled className="button is-success is-rounded is-fullwidth">Go to Venue</button>
    }

    return (
      <button
        type="button"
        className="button is-primary is-rounded is-fullwidth"
        onClick={handleConnect}
      >
        Connect
      </button>
    );
  }

  const pluralize = () => {
    if (new BigNumber(qtyAllow).gt(1)) {
      return 's';
    }

    return '';
  }

  const title = () => {
    return trainingCentersJson[id].title;
  }

  const src = () => {
    return trainingCentersJson[id].src;
  }

  return (
    <div className="card">
      <div className="card-image">
        <figure className="image is-4by3">
          <iframe
            title={title()}
            className="has-ratio"
            width="640"
            height="360"
            frameborder="0"
            allowfullscreen
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            src={src()}
          />
        </figure>
      </div>
      <div className="card-content content has-background-light">
        <h3 className="is-familiy-secondary has-text-primary">{title()}</h3>
        <ul>
          <li>Select 1 player and {qtyAllow} Sport NFT{pluralize()} or {qtyAllow} Training Card{pluralize()}.</li>
          <li>Enhancement fee: {new BigNumber(fee).dividedBy(new BigNumber(10).pow(6)).toFormat()} USDC</li>
        </ul>
        {renderConnectOrGoButton()}
      </div>
    </div>
  );
}

export default TrainingCenterCard;
