import asyncConnectWallet from '../blockchain/async-connect-wallet';
import walletInitialState from '../states/wallet-initial-state';

const createWalletSlice = (set, get) => ({
  walletState: walletInitialState,
  connection: async () => {
    const rs = await asyncConnectWallet(get().walletState.selectedProvider);
    set((prev) => ({ walletState: { ...prev.walletState, ...rs } }));
  },
  connect: (selectedProvider) => {
    if (selectedProvider) {
      localStorage.setItem('selectedProvider', selectedProvider);
    }
    set((prev) => ({ walletState: { ...prev.walletState, selectedProvider } }));
  },
  disconnect: () => {
    localStorage.removeItem('selectedProvider');
    set((prev) => ({ walletState: { ...prev.walletState, selectedProvider: undefined } }));
  }
});

export default createWalletSlice;
