import { useContext, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import gameCardSaleAbi from '../config/abis/game-card-sale.json';
import erc20Abi from '../config/abis/erc20.json';
import gameCardNftAbi from '../config/abis/game-card-nft.json';
import { getAddress, multiCall, loadContract } from '../services/utils';

import { WalletContext } from '../context/wallet';

const gameCardSaleAddress = getAddress('gameCardSale');
const nftAdminAddress = getAddress('nftAdmin');

const useGameCardSaleContract = () => {
  const { walletConnected } = useContext(WalletContext);
  const [gameCardSaleState, setGameCardSaleState] = useState({
    loading: true,
    packs: [],
    discounts: [],
    nftRemaining: 0,
    startTime: 0,
    started: false,
  });
  const [reload, setReload] = useState(0);

  useEffect(() => {
    const fetchSale = async () => {
      const results = await multiCall(gameCardSaleAbi, [
        {
          name: 'packsLength',
          address: gameCardSaleAddress
        },
        {
          name: 'discountsLength',
          address: gameCardSaleAddress
        },
        {
          name: 'startTime',
          address: gameCardSaleAddress
        },
        {
          name: 'gameCardsLength',
          address: gameCardSaleAddress
        }
      ]);

      const packsLength = new BigNumber(results[0]);
      const discountsLength = new BigNumber(results[1]);
      const startTime = new BigNumber(results[2]);
      const gameCardsLength = new BigNumber(results[3]);

      const accounts = [];
      const ids = [];
      for (let i = 0; gameCardsLength.gt(i); i++) {
        accounts.push(nftAdminAddress);
        ids.push(i);
      }
      const gameCardNftContract = loadContract('gameCardNft', gameCardNftAbi);
      const values = await gameCardNftContract.balanceOfBatch(accounts, ids);
      const nftRemaining = new BigNumber(values.reduce((partialSum, a) => partialSum + new BigNumber(a._hex).toNumber(), 0));

      const usdContract = loadContract('usdc', erc20Abi);
      const decimals = await usdContract.decimals();

      const saleCalls = new Array(packsLength.toNumber()).fill('pack').map((el, i) => ({
        address: gameCardSaleAddress,
        name: 'packs',
        params: [i],
      }));

      for (let i = 0; discountsLength.gt(i); i++) {
        saleCalls.push({
          address: gameCardSaleAddress,
          name: 'discounts',
          params: [i],
        });
      }

      const saleResults = await multiCall(gameCardSaleAbi, saleCalls);
      const packs = new Array(packsLength.toNumber()).fill('pack').map((el, i) => ({
        id: i,
        maxSellQty: new BigNumber(saleResults[i].maxSellQty._hex).toJSON(),
        price: new BigNumber(saleResults[i].price._hex).div(new BigNumber(10).pow(decimals)).toJSON(),
        qty: new BigNumber(saleResults[i].qty._hex).toJSON(),
      }));
      const discountsOffset = packsLength.toNumber();
      const discounts = new Array(discountsLength.toNumber()).fill('discount').map((el, i) => ({
        id: i,
        per: new BigNumber(saleResults[discountsOffset + i].per._hex).toJSON(),
        qty: new BigNumber(saleResults[discountsOffset + i].qty._hex).toJSON(),
      }));

      setGameCardSaleState({
        loading: false,
        packs,
        discounts,
        nftRemaining: nftRemaining.toJSON(),
        startTime: startTime.toJSON(),
        started: startTime.times(1000).lt(Date.now()),
      });
    }

    fetchSale();
  }, [walletConnected, setGameCardSaleState, reload]);

  return {
    walletConnected,
    gameCardSaleState,
    setReload
  }
}

export default useGameCardSaleContract;
