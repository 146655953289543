import React, { useEffect, useState } from 'react';
import WorldCupNav from '../../shared/components/world-cup-nav';
import { classNames } from '../../services/utils';

import Loading from '../../shared/components/loading';

import standingsJson from '../../db/standings.json';

const WorldCupStandingsPage = () => {
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const fetchStandings = async () => {
      setGroups(standingsJson);
      setLoading(false);
    }
    fetchStandings();
  }, [setGroups, setLoading]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div
        style={{
          backgroundImage: 'url(/images/hero.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          zIndex: '-1'
        }}
      />
      <WorldCupNav tab="standings">
        <h1 className="title has-text-light">World Cup Standings</h1>
      </WorldCupNav>
      <main role="main" className="section is-clipped">
        <div className="container">
          <div className="columns is-multiline">
            {groups.map((group) => (
              <div key={`group-${group.name}`} className="column is-half-desktop">
                <div className="box px-0 py-3">
                  <p className="title has-text-primary is-family-secondary ml-5">{group.name}</p>
                  <div className="table-container">
                    <table className="table is-fullwidth">
                      <thead>
                        <tr>
                          <th className="is-hidden-mobile" style={{ width: '1%' }} />
                          <th style={{ width: '100%' }}>Team</th>
                          <th className="has-text-right">MP</th>
                          <th className="has-text-right">W</th>
                          <th className="has-text-right">D</th>
                          <th className="has-text-right">L</th>
                          <th className="has-text-right">GF</th>
                          <th className="has-text-right">GA</th>
                          <th className="is-hidden-mobile has-text-right">GD</th>
                          <th className="has-text-right">Pts</th>
                        </tr>
                      </thead>
                      <tbody>
                        {group.standings.map((standing) => (
                          <tr
                            key={`standing-${group.name}-${standing.position}`}
                            className={classNames([1, 2].includes(standing.position) && 'has-background-success-light')}
                          >
                            <td className="is-hidden-mobile" style={{ width: '1%' }}>{standing.position}</td>
                            <td
                              style={{ width: '100%' }}
                            >
                              <div className="is-flex is-align-items-center">
                                <figure className="image is-24x24 mr-2">
                                  <img className="is-rounded" src={`/images/flags/${standing.teamName}.png`} style={{ height: '100%', objectFit: 'cover', border: '1px solid #ddd' }} alt={standing.teamName} />
                                </figure>
                                <span className="is-hidden-mobile">{standing.teamName}</span>
                              </div>
                            </td>
                            <td className="has-text-right">{standing.gamesPlayed}</td>
                            <td className="has-text-right">{standing.won}</td>
                            <td className="has-text-right">{standing.draw}</td>
                            <td className="has-text-right">{standing.lost}</td>
                            <td className="has-text-right">{standing.goalsScored}</td>
                            <td className="has-text-right">{standing.goalsAgainst}</td>
                            <td className="is-hidden-mobile has-text-right">{standing.goalsScored - standing.goalsAgainst}</td>
                            <td className="has-text-right"><strong>{standing.points}</strong></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
    </>
  );
}

export default WorldCupStandingsPage;
